import * as Survey from 'survey-core';

const CustomComponentIcon = (imageURL) => {
  return `<svg xmlns="http://www.w3.org/2000/svg">
    <image height="18" y="3" href="${imageURL}" />
</svg>
`;
}

export const registerCustomIcon = (iconName, imageURL) => {
  // https://surveyjs.io/form-library/documentation/icons
  Survey.SvgRegistry.registerIconFromSvg(iconName, CustomComponentIcon(imageURL));
}
