import {loadCommands} from './commands';
import {loadComponent} from './component';
import {loadBlock} from './block';
import {userIsAdmin} from '../utils/user';

export const componentKey = 'custom-html-plugin__code';
export const componentType = 'CustomHtml';
export const componentCommandName = 'custom-html:open-modal';
export const svg = `<svg viewBox="0 0 24 24">
    <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
</svg>`;

export function initCustomHtmlComponent(editor, opts = {}) {
  const defaults = {
    toolbarBtnCustomCode: {},
    modalTitle: 'Insert HTML code',
    modalButtonLabel: 'Save',
    codeViewOptions: {
      styleActiveLine: true,
      lineWrapping: true,
    },
    placeholderContent: '<span>Insert HTML code</span>',
  };

  const options = {...defaults, ...opts};

  loadCommands(editor, options);
  loadComponent(editor, options);

  if (userIsAdmin()) {
    loadBlock(editor, options);
  }
}
