import loadComponent from './component';
import loadBlock from './block';
import loadCommands from './commands';

export const FeedAggregatorDataProvider = {
  wordpressApi: 'wp-api',
  typesense: 'typesense',
};

export const FeedAggregatorLayout = {
  three_cols: { id: '3-cols', name: '3 columns' },
  four_cols: { id: '4-cols', name: '4 columns' },
};

export default function initFeedAggregatorComponent (editor, opts) {
  const defaults = {
    modalTitle: 'Manage feed aggregator sources',
    minItemsToDisplay: 3,
    maxItemsToDisplay: 48,
    componentType: 'FeedAggregator',
    componentKey: 'feed_aggregator_sources',
    componentCommandName: 'feed-aggregator:open-modal'
  };

  const options = {...defaults, ...opts};

  loadCommands(editor, options);
  loadComponent(editor, options);
  loadBlock(editor, options);
}
