import { ctasOnly, keyTopics, mainAreaContent, quickLinks, mediaCard, textCard } from './banner-components';

const bannerOptionsIds = {
  noSidebar: 'no-sidebar',
  quickLinks: 'quick-links',
  keyTopics: 'key-topics',
  ctasOnly: 'ctas-only',
  mediaCard: 'media-card',
  textCard: 'text-card',
}

const bannerOptions = [
  {value: bannerOptionsIds.noSidebar, name: 'No Sidebar'},
  {value: bannerOptionsIds.quickLinks, name: 'Quick Links'},
  {value: bannerOptionsIds.keyTopics, name: 'Key Topics'},
  {value: bannerOptionsIds.ctasOnly, name: 'CTAs Only'},
  {value: bannerOptionsIds.mediaCard, name: 'Media Card'},
  {value: bannerOptionsIds.textCard, name: 'Text Card'},
];

/**
 * Banner component.
 *
 * @param {Object} dc
 *   Dom components manager.
 * @param {Object} config
 *   Component config.
 */
export default (dc, config) => {
  const traits = [
    {
      type: 'select',
      label: 'Page type',
      name: 'page_type',
      changeProp: 1,
      options: [
        {value: 'topic-area', name: 'Topic Area'},
        {value: 'topic-page', name: 'Topic Page'},
      ],
    },
    {
      type: 'select',
      label: 'Sidebar',
      name: 'sidebar',
      changeProp: 1,
      options: bannerOptions
    }
  ];
  const components = [
    {
      type: 'container',
      classes: ['container'],
      ...config.defaultProps,
      components: [
        {
          type: 'row',
          classes: ['row', 'row-override'],
          ...config.defaultProps,
          components: [
            {
              type: config.subComponents.main.type,
              components: mainAreaContent(),
            },
            {
              type: config.subComponents.sidebar.type,
              components: [] // By default sidebar is empty.
            }
          ]
        }
      ]
    },
  ];

  dc.addType(config.componentType, {
    model: {
      defaults: {
        name: config.componentName,
        copyable: false,
        removable: true,
        highlightable: false,
        traits: traits,
        classes: config.css.root,
        components: components,
      },
      init() {
        this.listenTo(this, 'change:page_type', this.__onChangePageType);
        this.listenTo(this, 'change:sidebar', this.__onChangeSidebar);
      },
      __onChangePageType(_, value) {
        const reservedClassNames = ['topic-area', 'topic-page']
        let originalClasses = this.getClasses().filter(c => !reservedClassNames.includes(c));
        const newClasses = [...originalClasses, value];
        this.setClass(newClasses);
      },
      __onChangeSidebar(_, value) {
        const sidebarComponent = this.find(`.${config.css.sidebar}`);

        if (!sidebarComponent.length) {
          return;
        }

        const el = sidebarComponent.pop();

        // Clear sidebar
        el.components([]);
        el.addAttributes({ 'role': value });

        const sidebarComponents = this.buildSidebar(value);

        if (sidebarComponents.length) {
          this.addClass(`${config.css.root}--with-sidebar`);
        } else {
          this.removeClass(`${config.css.root}--with-sidebar`);
        }

        el.append(sidebarComponents);
      },
      /**
       * Builds banner sidebar.
       *
       * @param {String} context
       * @returns []
       */
      buildSidebar(context) {
        const componentMap = {
          [bannerOptionsIds.quickLinks]: quickLinks,
          [bannerOptionsIds.keyTopics]: keyTopics,
          [bannerOptionsIds.ctasOnly]: ctasOnly,
          [bannerOptionsIds.mediaCard]: mediaCard,
          [bannerOptionsIds.textCard]: textCard,
        };

        try {
          return componentMap[context]();
        } catch (_e) {
          return [];
        }
      }
    },
  });
}
