import {loadBlock} from "./block";
import {loadComponent} from "./component";

export const componentType = 'DiscourseTopics';

const initDiscourseTopicsComponent = (editor, opts = {}) => {
  loadComponent(editor, opts);
  loadBlock(editor, opts);
};

export default initDiscourseTopicsComponent;
