import * as React from 'react';
import {useEffect, useRef, useState} from 'react';

function DiscourseTopics({title, description, subtitle, slug, cta_text, cta_url, children, childSelector}) {
  const [topics, setTopics] = useState([])

  let childElement = null;
  if (childSelector) {
    childElement = document.querySelector(childSelector)
  }
  const childElementRef = useRef(childElement);
  if (childElementRef && childElementRef.current) {
    children = <span dangerouslySetInnerHTML={{__html: childElementRef.current.innerHTML}}></span>;
    children = childElementRef.current.innerHTML;
    if (childElementRef.current.parentElement) {
      childElementRef.current.parentElement.removeChild(childElementRef.current);
    }
  }
  if (description) {
    children = description;
  }

  useEffect(() => {
    if (!slug) {
      return;
    }
    const params = new URLSearchParams();
    params.set('slug', slug);
    const url = `/discourse/topics?${params.toString()}`;
    fetch(url)
      .then(response => response.json())
      .then((response) => {
        setTopics(response);
      })
      .catch(e => console.log(e))
  },[slug]);

  return (
    <section className="jetson-project-page project-section project-section-even">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 forum-description-wrapper">
            <h3 className="h--small mb-3">{title}</h3>
            {(childSelector || description) && <div className="forum-description" dangerouslySetInnerHTML={{__html: children}}></div>}
            {!childSelector && !description && <div className="forum-description">{children}</div>}
            <a href={cta_url} className='btn btn-cta' target="_blank">{cta_text}</a>
          </div>
          <div className="col-lg-6 col-md-12">
            <h3 className="h--small mb-3">{subtitle}</h3>
            <ul className="discussions-list">
                  {topics.length > 0 && topics.map(({title, link, tags }) => {
                    return <li key={link} className="discussion-item">
                      <div className="discussion-label-wrapper">
                        {tags.length > 0 && tags.map(tag => {
                          return <div key={tag} className='discussion-tag'>{tag}</div>
                        })}
                        {title && link && <a href={link} target="_blank">{title}</a>}
                      </div>
                      <div className="discussion-item-arrow">
                        <svg fill="none" height="21" viewBox="0 0 13 21" width="13" xmlns="http://www.w3.org/2000/svg">
                          <path d="m1.18176 1.62524 9.00004 8.99996-9.00004 9" stroke="#76b900" strokeWidth="3"/>
                        </svg>
                      </div>
                    </li>
                  })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DiscourseTopics;
