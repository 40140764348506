import defaultOptions from './options';
import loadComponents from './components';
import loadBlocks from './blocks';

/**
 * Tabs component entry point.
 */
export default (editor, opts = {}) => {
  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponents(editor, options);
  loadBlocks(editor, options);
}
