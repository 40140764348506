export const cssEditorPreview = {
  position: 'relative',
};

export const cssEditorPreviewOverlay = {
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  position: 'absolute',
  cursor: 'pointer',
  filter: 'grayscale(0.9)'
};

export const cssSvgIcon = {
  width: '42px',
  background: 'rgb(117 185 0)',
  fill: 'white',
  marginTop: '0.3rem',
  marginLeft: '0.3rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  borderRadius: '5px',
  borderBottom: 'solid 3px rgb(98 152 5)',
}
