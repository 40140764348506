import loadBlock from './block';
import loadComponent from './component';

/**
 * Contextual hero banner entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'ContextualHeroBanner',
    componentName: 'Contextual Hero Banner',
    category: 'React Components',
    subComponents: {
      main: {
        type: 'ContextualHeroBannerMain',
        name: 'Hero Banner: Main area',
        supportedElements: `h1,h2,h3,h4,h5,p`,
      },
      sidebar: {
        type: 'ContextualHeroBannerSidebar',
        name: 'Hero Banner: Sidebar',
      }
    },
    css: {
      root: ['nv-contextual-hero-banner'],
      mainArea: ['nv-contextual-hero-banner__main'],
      sidebar: ['nv-contextual-hero-banner__sidebar'],
    },
    defaultProps: {
      copyable: false,
      removable: false,
      selectable: false,
      hoverable: false,
    }
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
}
