import loadComponent from './component';
import loadBlock from './block';

export const componentType = 'PageNavigation';

const initPageNavigation = (editor, opts) => {
  loadComponent(editor, opts);
  loadBlock(editor, opts);
}

export default initPageNavigation;
