import Banner from './Banner';
import MainArea from './MainArea';
import Sidebar from './Sidebar';

export default (editor, config = {}) => {
  const dc = editor.DomComponents;

  [
    Banner,
    MainArea,
    Sidebar
  ].map(c => c(dc, config));
}
