import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Code } from 'react-content-loader';

async function fetchData(url) {
  const response = await fetch(url);
  const json = await response.json();
  if (!response.ok) {
    throw new Error(`Response code: ${response.statusCode}`);
  }

  return json;
}

/**
 * Chart preloader.
 *
 * @returns {Element}
 * @constructor
 */
function ChartPreloader() {
  return <Code speed={2} width={340} height={90} viewBox="0 0 340 90"/>
}

/**
 * Chart component.
 *
 * @param {Object} props
 * @param {Object} props.chartData
 * @param {String} props.alt
 *
 * @returns {Element}
 * @constructor
 */
function LegacyChartElement(props) {
  const {chartData, alt} = props;
  const ref = useRef();
  const [rendered, setRendered] = useState(false);
  const {
    chartTitle = '',
    chartSubtitle = '',
    bars,
    ticks,
    xAxisLabel,
    chartFootnote,
    isGrouped,
    legendData,
    barPadding = 5
  } = chartData;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const isDomElementVisible = jQuery(ref.current).is(':visible');
    const chart = ref.current.querySelector('svg[data-nvidia-chart]');
    const svgChart = d3.select(chart);

    $(document)
      .on('click', '.accordion-item__heading', function () {
        const parent = $(this).closest('.accordion-item');

        if (!parent) {
          return;
        }

        const accordionId = $(parent).attr('id');
        if (isDomElementVisible || rendered || !accordionId) {
          return;
        }

        try {
          ReactRailsUJS.mountComponents(`#${accordionId} .accordion-item__content`);
          setRendered(true);
        } catch (e) {

        }
      })
      .on('click', '.nav-item[role="tab"]', function () {
        if (!rendered) {
          window.dispatchEvent(new Event('resize'));
          setRendered(true);
        }
      });

    if (isGrouped) {
      createGroupedHorizontalBarChart(svgChart, bars, barPadding, legendData, ticks, null, xAxisLabel, false);
    } else {
      createHorizontalBarChart(svgChart, bars, barPadding, ticks, xAxisLabel, "", false);
    }
  }, [ref, rendered]);

  return (
    <div className='nv-chart nv-chart--legacy' ref={ref} title={alt}>
      <h4 className="chart-title nv-chart__title">{chartTitle}</h4>
      <p className="nv-chart__subtitle">{chartSubtitle}</p>
      <div className="nv-chart__legend legend"></div>
      <svg data-nvidia-chart="true" data-chart-legend=""></svg>
      <p className="chart-footnote nv-chart__footnote">{chartFootnote}</p>
    </div>
  );
}

/**
 * Legacy chart root component.
 *
 * @param props
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function LegacyChart (props) {
  const {source, alt} = props;
  const [chartData, setChartData] = useState({});
  const [loaded, setLoaded] = useState(false);

  const processFetchedData = (data) => {
    setChartData(data);
    setLoaded(true);
  }

  const handleError = (error) => {
    setLoaded(false);
    console.log(`Failed to fetch chart data. Reason: ${error.message}`);
  };

  useEffect(() => {
    fetchData(source).then(processFetchedData).catch(handleError);
  }, [source, loaded]);

  if (!loaded) {
    return <ChartPreloader />
  }

  return <LegacyChartElement chartData={chartData} alt={alt} />;
}
