import { componentLabel } from '../utils/editor';
import calendarLines from './calendar-lines.svg';

export default (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('AddEvent Calendar', calendarLines),
    category: options.category,
    content: { type: options.componentType },
  });
}
