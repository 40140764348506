/**
 * Icon component definition.
 *
 * @param {Object} editor
 * @param {String} componentType
 * @param {Object} commands
 */
export default (editor, {componentType, commands}) => {
  const componentDefinition = {
    model: {
      defaults: {
        'custom-name': 'Icon',
        tagName: 'i',
        classes: 'nv-icon',
        editable: false,
        draggable: true,
        iconName: '',
        traits: [
          {
            type: 'select',
            name: 'iconFamily',
            label: 'Icon family',
            options: [
              {id: 'fa', name: 'Font Awesome'},
            ],
            value: 'fa'
          },
          {
            name: 'iconName',
            label: 'Icon',
            type: 'text',
            changeProp: 1
          },
          {
            type: 'button',
            label: false,
            name: 'selectIcon',
            text: 'Select an icon',
            command: (editor, sender, opts) => {
              editor.runCommand(commands.openIconSelectorModal, {model: sender.target});
            },
          },
        ],
      },
      init() {
        this.listenTo(this, 'change:iconName', this.onIconChanged);
      },
      onIconChanged(selected, value, _opts) {
        selected.set({iconName: value});
        selected.addAttributes({'data-icon': value});
      }
    },
    isComponent: function (el) {
      if (el && ['nv-icon'].includes(el.classes)) {
        return {tagName: 'i'};
      }
    },
  };

  const {DomComponents} = editor;
  DomComponents.addType(componentType, componentDefinition);
};
