import * as React from 'react';

/**
 * Actions component.
 *
 * @param {Array} links
 * @returns {JSX.Element}
 * @constructor
 */
export const Actions = ({links}) => {
  if (links && Array.isArray(links) && links.length) {
    return <div className="fsf-result__actions fsf-result__links">
      {links.map((link, idx) => {
        const {url, target} = link;
        const linkAttr = {key: idx, href: url};
        if (target) {
          linkAttr.target = target;
        }
        return <a {...linkAttr}>{link.text}</a>;
      })}
    </div>;
  }

  return <></>;
}
