import * as React from 'react';
import { useState, useEffect } from 'react';
import { handleRequestError, parseOptionsString } from './faceted-search/react/helper';
import { FacetedSearchLayoutLoader } from './faceted-search/react/FacetedSearchLayoutLoader';
import { FacetedSearchLayout } from './faceted-search/react/FacetedSearchLayout';
import {
  CardDataFields,
  ItemsPerPageDefault,
  AccordionDataFields,
  mappingTypes,
} from './faceted-search/data-mapping';
import { sanitizeEditorOption } from './utils/editor';
import { QueryContextProvider, ViewOptionsContextProvider } from './faceted-search/react/Context';
import { BrowserRouter as Router, Route } from 'react-router-dom';

/**
 * Faceted search component.
 *
 * @param {Object} props
 * @param {String} props.collection_id
 * @param {String} props.view_mode
 * @param {String} props.title
 * @returns {JSX.Element}
 * @constructor
 */
export default function FacetedSearch(props) {
  const {
    collection_id,
    view_mode,
    title,
    items_per_page,
    card_options,
    query_by,
    sort_by,
    sort_by_label,
    layout,
    compact_mode_dropdown_label,
    compact_mode_cta_text,
    compact_mode_cta_url,
  } = props;
  const {filter_by, disabled_filters} = props;
  const [loaded, setLoaded] = useState(false);
  const [itemsPerPage, ] = useState(items_per_page ? parseInt(items_per_page) : ItemsPerPageDefault);
  const [protocol, setProtocol] = useState('https');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [viewOptions, setViewOptions] = useState({});
  const [collection, setCollection] = useState({
    name: '',
    filters: [],
    field_mappings: {
      card: CardDataFields,
      accordion: AccordionDataFields
    }
  });
  const [customSortBy, setCustomSortBy] = useState('');
  const [customSortByLabel, setCustomSortByLabel] = useState('');

  useEffect(() => {
    let abortController = new AbortController();
    setCustomSortBy(sort_by);
    setCustomSortByLabel(sort_by_label);

    const collectionURL = `/typesense_collections/${collection_id}.json`;
    fetch(collectionURL, {signal: abortController.signal})
      .then(data => data.json())
      .then(data => {
        if (!data.collection) {
          return;
        }
        const {protocol, host, port, api_key, collection} = data;
        setProtocol(protocol)
        setHost(host);
        setPort(port);
        setApiKey(api_key);
        setCollection(collection);
        setLoaded(true);
      })
      .catch(e => handleRequestError(e));

    return () => {
      abortController.abort();
    };
  }, [view_mode, title, items_per_page, collection_id, query_by, sort_by, sort_by_label, customSortBy, customSortByLabel, filter_by, disabled_filters, layout]);

  useEffect(() => {
    if (card_options && view_mode === mappingTypes.card) {
      const cardOptions = sanitizeEditorOption(card_options);
      const parsed = parseOptionsString(cardOptions);
      setViewOptions(parsed);
    }
  }, [view_mode, card_options, layout]);

  if (loaded === false) {
    return <FacetedSearchLayoutLoader title={title}/>
  }

  return (
    <Router>
      <Route path={window.location.pathname}>
        <QueryContextProvider>
        <ViewOptionsContextProvider>
          <FacetedSearchLayout
            collection={collection}
            itemsPerPage={itemsPerPage}
            protocol={protocol}
            host={host}
            port={port}
            apiKey={apiKey}
            viewMode={view_mode}
            viewOptions={viewOptions}
            queryBy={query_by}
            customSortBy={customSortBy}
            customSortByLabel={customSortByLabel}
            filterBy={filter_by}
            disabledFilters={(disabled_filters || '').split(',').map(i => i.trim()).filter(i => i.length)}
            layout={layout}
            compactModeDropdownLabel={compact_mode_dropdown_label}
            compactModeCtaText={compact_mode_cta_text}
            compactModeCtaUrl={compact_mode_cta_url}
            title={title}/>
        </ViewOptionsContextProvider>
        </QueryContextProvider>
      </Route>
    </Router>
  );
}
