/**
 * Main area component.
 *
 * @param {Object} dc
 *   Dom components manager.
 * @param {Object} config
 *   Component config.
 */
export default (dc, config) => {
  dc.addType(config.subComponents.main.type, {
    model: {
      defaults: {
        name: config.subComponents.main.name,
        ...config.defaultProps,
        draggable: config.subComponents.main.supportedElements,
        droppable: config.subComponents.main.supportedElements,
        classes: config.css.mainArea,
      }
    }
  });
}
