import { componentLabel } from "../utils/editor";
import sidebarFlip from './sidebar-flip.svg';

export default (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('Contextual hero banner', sidebarFlip),
    category: options.category,
    content: { type: options.componentType },
  });
}
