function getAllComponents(model, result = []) {
  result.push(model);
  model.components().each(mod => getAllComponents(mod, result))
  return result;
}

/**
 * On-load event handlers.
 *
 * @param {Object} editor
 * @param {Object} options
 */
export default (editor, options = {}) => {
  $(options.containerId ? options.containerId : document).on('click', '.gjs-icon-selector .gjs-fa-icon-wrapper', function(event) {
    const componentId = this.dataset['componentId'];
    const icon = this.dataset['icon'];

    let componentModel = getAllComponents(editor.getWrapper()).find(model => model.cid === componentId);

    if (componentModel) {
      componentModel.set({ iconName: icon });
      componentModel.addAttributes({ 'data-icon': icon });
    }
    editor.Modal.close();
  });

  editor.on('component:add', (model) => {
    if (model.attributes.type === options.componentType && model.components().length === 0) {
      // set default icon
      if (model.get('iconName').length === 0) {
        model.set({ iconName: 'external-link' });
      }
    }
  });
};
