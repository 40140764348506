const PA_TERMS = I18n.t('program_applications.form.terms');

const termsCheckbox = (data) => {
  let text = PA_TERMS;
  const {customTermsText} = data;

  if (customTermsText && String(customTermsText).trim().length > 0) {
    text = customTermsText;
  }

  return {
    type: 'checkbox',
    name: 'pa-terms',
    titleLocation: 'hidden',
    required: true,
    choices: [{value: '1', text: text}],
    errorLocation: 'bottom',
    validators: [
      {
        type: 'expression',
        text: 'Response required.',
        expression: '{pa-terms} notempty',
      }
    ]
  }
};

const profilePageElement = (showEmailTooltip) => {
  return {
    name: 'page_profile',
    elements: [
      {
        type: 'user-profile',
        name: 'user-profile',
        titleLocation: 'hidden',
        showExtendedProfile: true,
        disabledFields: ['email'],
        isProgramApplicationForm: true,
        showEmailTooltip: showEmailTooltip,
      }
    ]
  }
};

export function addProgramApplicationElements(data, showEmailTooltip, isNewUser) {
  data.pages[0]['elements'].push(termsCheckbox(data));

  data.pageNextText = I18n.t('program_applications.form.save_and_continue');
  data.completeText = I18n.t('program_applications.form.submit');

  if (isNewUser) {
    return;
  }

  data.pages.unshift(profilePageElement(showEmailTooltip));
}

export function addProgramApplicationFormProgressBar(model) {
  model.addNavigationItem({
    id: 'sv-nav-current-progress',
    component: 'sv-pa-form-steps',
    data: {
      model,
    },
    visibleIndex: 25,
    active: false,
    css: 'nav-button',
    innerCss: 'sd-btn nav-input',
  });
}
