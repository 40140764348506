import loadComponent from './component';
import loadBlock from './block';

export default function initImageComparisonSlider(editor, opts) {
  const defaults = {
    componentType: 'ImageComparisonSlider',
    category: 'React Components',
  };

  const options = {...defaults, ...opts};

  loadComponent(editor, options);
  loadBlock(editor, options);
}
