export const userIsAdmin = () => {
  return window.userRole && window.userRole === 'admin';
}

export const getUserProfile = () => {
  let data = {};
  $.ajax({
    async: false,
    url: '/api/user.json',
    type: 'GET',
    dataType: 'json',
    success: function (resp) {
      data = resp;
    },
    error: function (){
      data = {};
    }
  });

  return data;
}
