import { componentLabel } from '../utils/editor';
import icon from './chart-mixed.svg';

export default function (editor, options) {
  const {componentType, category, componentName} = options;

  editor.BlockManager.add(componentType, {
    label: componentLabel(componentName, icon),
    category: category,
    content: '<LegacyChart />'
  });
}
