import LegacyChart from '../LegacyChart';

export default function (editor, options) {
  const {componentType, componentName} = options;

  const componentDefinition = {
    extend: 'react-component',
    model: {
      defaults: {
        'custom-name': componentName,
        component: LegacyChart,
        stylable: false,
        resizable: false,
        editable: false,
        draggable: true,
        droppable: true,
        traits: [
          {
            type: 'text',
            name: 'source',
            label: 'Source',
            placeholder: 'https://example.com/data.json'
          },
          {
            type: 'text',
            name: 'alt',
            label: 'Alt'
          }
        ],
      },
    },
    isComponent: el => el.tagName === componentType.toUpperCase()
  };
  editor.Components.addType(componentType, componentDefinition);
}
