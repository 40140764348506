import { Configure, HitsPerPage, Pagination, ScrollTo } from "react-instantsearch-dom";
import { FacetedSearchSidebar } from "./FacetedSearchSidebar";
import { FacetedSearchStats } from "./FacetedSearchStats";
import { FacetedSearchClearFilters } from "./FacetedSearchClearFilters";
import { FacetedSearchPills } from "./FacetedSearchPills";
import { FieldsMappingContextProvider } from "./Context";
import * as React from "react";

export const FacetedSearchLayoutStandard = (props) => {
  const {
    filterBy,
    title,
    sortBy,
    customSortBy,
    customSortByLabel,
    filters,
    disabledFilters,
    collection,
    quickFiltersRef,
    cssClasses,
    cssClassesHeading,
    cssClassesStats,
    cssClassesFacetedSearchPills,
    itemsPerPage,
    SearchResults,
    viewMode,
    fieldsMapping,
  } = props;

  return (
    <>
      {filterBy && filterBy.length && <Configure filters={filterBy}/>}
      <div className="container faceted-filters">
        <div className="row">
          {title && <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className={cssClassesHeading.join(' ')}>{title}</h2>
          </div>}
          <div className="col-lg-3 col-md-12 col-sm-12">
            <FacetedSearchSidebar sortBy={sortBy}
                                  customSortBy={customSortBy}
                                  customSortByLabel={customSortByLabel}
                                  filters={filters}
                                  disabledFilters={disabledFilters}
                                  collectionName={collection.name}/>
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12">
            <div className={cssClassesStats.join(' ')}>
              <FacetedSearchStats/>
              <HitsPerPage defaultRefinement={itemsPerPage}
                           items={[{value: itemsPerPage, label: `${itemsPerPage} items per page`}]}/>
            </div>

            <div className={cssClassesFacetedSearchPills.join(' ')} ref={quickFiltersRef}>
              <FacetedSearchClearFilters/>
              <div className="ais-CurrentRefinements-outer">
                <FacetedSearchPills filters={filters} container={quickFiltersRef}/>
              </div>
            </div>

            <div className={cssClasses.join(' ')}>
              <FieldsMappingContextProvider>
                <ScrollTo>
                  <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
                </ScrollTo>
              </FieldsMappingContextProvider>
            </div>

            <div className="faceted-filters__pagination">
              <Pagination showFirst={true} showLast={true} translations={{previous: "Previous", next: "Next"}}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};
