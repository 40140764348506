export default (editor, config = {}) => {
  const traits = [
    {
      type: 'text',
      name: 'calendar_id',
      label: 'Calendar ID',
      changeProp: 1,
    },
    {
      type: 'text',
      name: 'container_id',
      label: 'Container ID',
      changeProp: 1,
    },
    {
      type: 'text',
      name: 'no_results_text',
      label: 'No results text',
      placeholder: 'No upcoming events.',
      changeProp: 1,
    },
    {
      full: 1,
      type: 'button',
      label: false,
      text: 'Preview',
      command: () => {
        const {previewPageURL} = window;
        if (previewPageURL) {
          window.open(previewPageURL, "_blank");
        }
      },
    }
  ];

  const widgetPreview = () => {
    let cards = '';
    for (let i = 0; i < 4; i++) {
      cards += `
        <div class="col-xl-3 nv-recommendation">
          <div class="nv-recommendation__card">
            <div class='nv-recommendation__body'>
              <div class="nv-recommendation_pretitle"><p class="p--small mb-0">Wednesday, July 31 11:00 AM - 12:30 PM (MDT)</p></div>
              <div class='nv-recommendation__content'>
                <div class='nv-recommendation__title'>
                  <h3 class='h--smallest mt-0 mb-0'>Event #${i + 1}</h3>
                </div>                
              </div>
              <div class="nv-recommendation__links">
                <a href='#' class='link-cta pseudo-stretched-link'>Add to Calendar</a>
              </div>
            </div>
          </div>
        </div>
        `
    }
    return `
        <div class="nv-recommender nv-recommender--preview">
            <div class="container">
              <div class="row">${cards}</div>
            </div>
        </div>`;
  }

  const componentDefinition = {
    model: {
      defaults: {
        name: config.componentName,
        copyable: false,
        removable: true,
        highlightable: false,
        traits: traits,
        classes: config.css.root,
        components: [
          {tagName: 'div', selectable: false, content: widgetPreview()}
        ],
      },
      init() {
        this.listenTo(this, 'change:container_id', this.__onChangeProperty);
        this.listenTo(this, 'change:calendar_id', this.__onChangeProperty);
        this.listenTo(this, 'change:no_results_text', this.__onChangeProperty);
      },
      __onChangeProperty(_, value) {
        const payload = {
          ['calendar_id']: this.props()['calendar_id'],
          ['container_id']: this.props()['container_id'],
          ['no_results_text']: this.props()['no_results_text'],
        }

        const props = JSON.stringify(payload);
        this.components([{tagName: 'div', selectable: false, content: widgetPreview(), attributes: {id: this.props()['container_id'], ['data-react-props']: props}}]);
      },
    },
  }

  editor.Components.addType(config.componentType, componentDefinition);
}
