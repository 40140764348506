import Webform from '../Webform';
import {AutocompleteModel, TraitTypeAutocomplete} from '../traits/autocomplete';

const loadComponent = (editor, options) => {
  editor.Components.addType(options.componentType, {
    extend: 'react-component',
    model: {
      init() {
        fetch(options.optionsEndpoint).then(data => data.json())
          .then(data => {
            const forms = data.results.map((element) => {
              return {
                id: element.id,
                name: element.text,
              };
            });
            const selectBox = this.getTrait('formId');
            if (selectBox) {
              selectBox.set('options', forms);
            }
          });
      },
      defaults: {
        component: Webform,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          editable: true
        },
        traits: [
          {
            type: TraitTypeAutocomplete,
            model: AutocompleteModel.form,
            placeholder: 'Select a form...',
            label: 'Form',
            name: 'formId',
            required: true,
          }
        ]
      }
    },
    isComponent: (el) => el.tagName === options.componentType.toUpperCase(),
  });
};

export default loadComponent;
