import loadComponent from './component';
import loadBlock from './block';

const initGalleryWidget = (editor, opts) => {
  const defaults = {
    componentType: 'GalleryWidget'
  };
  const options = {...defaults, ...opts};

  loadComponent(editor, options);
  loadBlock(editor, options);
}

export default initGalleryWidget;
