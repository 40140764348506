export function initPageNavigationScrolling() {
  const handleNavBarClass = () => {
    mobileNavBar.classList.toggle('mobile-navigation-container__fixed', window.scrollY > 100);
  };

  const handlePageScrolling = () => {
    const anchors = document.querySelectorAll('.toc-item');
    if (!anchors.length) {
      return;
    }

    const bodyRect = document.body.getBoundingClientRect();
    const sections = [];
    const handleAnchors = (el) => {
      const anchor = document.getElementById(el.dataset.anchorId);
      sections.push({id: anchor.id, offset: parseFloat(el.dataset['offset'])});

      const elemRect = el.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      el.dataset['offset'] = offset.toString();
    };
    anchors.forEach(handleAnchors);

    if (!sections.length) {
      return;
    }

    let currentSection = null;
    sections.forEach((sect) => {
      if (window.scrollY > (sect.offset - 70)) {
        currentSection = sect;
        return;
      }
    });

    if (!currentSection) {
      if (!sections[0]) {
        return;
      }
      currentSection = sections[0];
    }

    const pageNav = document.querySelector('aside .page-navigation');
    if (!pageNav) {
      return;
    }

    pageNav
      .querySelectorAll('.page-quick-links__link.active')
      .forEach(activeLink => activeLink.classList.remove('active'));

    pageNav
      .querySelector(`a[href="#${currentSection.id}"]`)
      .classList
      .add('active');
  };

  const mobileNavBar = document.getElementById('page-mobile-nav-container');
  if (mobileNavBar) {
    window.addEventListener('scroll', handleNavBarClass);
    window.addEventListener('scroll', handlePageScrolling);
  }

}
