import * as React from 'react';
import {cssEditorPreview, cssEditorPreviewOverlay, cssSvgIcon} from './custom-html/styles';
import {useEffect, useState} from 'react';

function CustomHtml(props) {
  let {children} = props;

  if (children) {
    return (
      <div className="editor-preview" style={cssEditorPreview}>
        <div className="editor-preview__overlay" style={cssEditorPreviewOverlay}>
          <svg viewBox="0 0 24 24" style={cssSvgIcon}>
            <path
              d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
          </svg>
        </div>
        {children}
      </div>
    );
  }

  return (<></>);
}

export default CustomHtml;
