import * as React from 'react';
import { useEffect, useState } from 'react';
import closeIcon from './icons/close.svg'
import settingsIconSVG from "./icons/common-cog.svg";

export const SettingsIcon = (props) => {
  const {
    toggleCallback,
    onSettingsClick,
    selectedSettings,
    setSelectedSettings
  } = props;
  return <div className='align-self-center'>
    <button className='nv-recommender-settings-button' onClick={() => {
      onSettingsClick()
        .then(data => {
          setSelectedSettings(data ? data :selectedSettings);
        })
        .then(toggleCallback)
    }}>
      <img src={settingsIconSVG} alt=""/>
    </button>
  </div>
};

export const SettingsForm = (props) => {
  const staticLinks = {
    privacyPolicy: 'https://www.nvidia.com/en-us/about-nvidia/privacy-policy/',
    cookiePolicy: 'https://www.nvidia.com/en-us/about-nvidia/cookie-policy/',
  }
  const {interestAreas = [], selectedInterestAreas = [], onSaveCallback, toggleCallback} = props;
  const [selectedItems, setSelectedItems] = useState(new Map());

  useEffect(() => {
    const selectedItemsMap = new Map();
    selectedInterestAreas.forEach(item => selectedItemsMap.set(item, item));
    setSelectedItems(selectedItemsMap)
  }, []);

  return (
    <React.Fragment>
      <div id="nv-modal" className="nv-modal">
        <div className="container">
          <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12 col-sm-12">
            <div className="nv-modal__content">
              <button type="button" className="modal-form__close js-close-button" aria-label="Close" onClick={toggleCallback}>
                <img src={closeIcon} alt="Close modal"/>
              </button>
              <div id="modal-header" className="nv-modal__header">
                <h2 className='h--medium text-center mb-0'>Select Your Interests</h2>
                <p className='p--large text-center mb-0'>Refine your recommendations for more personalized results.</p>
              </div>
              <div className="nv-modal__body">
                <div className='nv-recommender-settings'>
                  {interestAreas.map(item => {
                    return <div className="form-check" key={item.value}>
                      <input className="form-check-input"
                             type="checkbox"
                             value={item.value}
                             onChange={(e) => {
                               const originalValues = new Map(selectedItems);
                               if (e.target.checked) {
                                 originalValues.set(e.target.value, e.target.value);
                               } else {
                                 originalValues.delete(e.target.value);
                               }
                               setSelectedItems(originalValues);
                             }}
                             checked={selectedItems.has(item.value)}
                             id={`recommender-option-${item.value}`}
                      />
                      <label className="form-check-label" htmlFor={`recommender-option-${item.value}`}>
                        {item.label}
                      </label>
                    </div>;
                  })}
                </div>
                <div className="nv-recommender-settings__footer d-flex justify-content-between align-items-center">
                  <div className="nv-recommender-settings__links">
                    <a href={staticLinks.privacyPolicy} target='_blank'>Privacy Policy</a>
                    <span className='footer-separator'>|</span>
                    <a href={staticLinks.cookiePolicy} target='_blank'>Cookie Policy</a>
                  </div>
                  <div className="nv-recommender-settings__actions">
                    <div className='btn-group'>
                      <button className='btn btn-cta btn-cta--light' onClick={toggleCallback}>Cancel</button>
                      <button className='btn btn-cta btn-cta' onClick={() => {
                        toggleCallback();
                        onSaveCallback(selectedItems);
                      }}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nv-modal-backdrop"></div>
    </React.Fragment>
  );
};
