import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

export const AccordionSize = {
  medium: {id: 'medium', name: 'Medium'},
  small: {id: 'small', name: 'Small'},
  smaller: {id: 'smaller', name: 'Smaller'},
  smallest: {id: 'smallest', name: 'Smallest'},
  paragraph: {id: 'paragraph', name: 'Paragraph'},
};

const AccordionItem = (props) => {
  const {title, badge, expanded, size, id} = props;
  let {children, childSelector} = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsOpen(!!expanded);
    }
  },[expanded]);

  let childElement = null;
  if (childSelector) {
    childElement = document.querySelector(childSelector)
  }

  const childElementRef = useRef(childElement);
  if (childElementRef && childElementRef.current) {
    children = childElementRef.current.innerHTML;
    if (childElementRef.current.parentElement) {
      childElementRef.current.parentElement.removeChild(childElementRef.current);
    }
  }

  const cssClasses = {
    root: classnames({
      'accordion-item': true,
      'accordion-item--open': !!isOpen
    }),

    heading: classnames({
      'h--medium': !size || size === AccordionSize.medium.id,
      'h--small': size === AccordionSize.small.id,
      'h--smaller': size === AccordionSize.smaller.id,
      'h--smallest': size === AccordionSize.smallest.id,
      'bold': size === AccordionSize.paragraph.id,
      'mb-0': true,
    }),
  };

  const headingTag = size === AccordionSize.paragraph.id ? 'p' : 'h3';
  const contentId = `nv-accordion-item-${id}`;
  const labelId = `nv-accordion-item-label-${id}`;

  return (
    <div className={cssClasses.root} id={id}>
      <div className='accordion-item__heading' aria-controls={contentId} aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)} role='button'>
        <div className='heading__text'>
          {React.createElement(headingTag, {className: cssClasses.heading, id: labelId}, title ? title : 'Accordion item')}
          {badge && <div className='accordion-item__badge'>{badge}</div>}
        </div>
        <div className='heading__icon'></div>
      </div>
      {childSelector && <div id={contentId} role="region" aria-labelledby={labelId} className='accordion-item__content' dangerouslySetInnerHTML={{__html: children}}></div>}
      {!childSelector && <div id={contentId} role="region" aria-labelledby={labelId} className='accordion-item__content'>{children}</div>}
    </div>
  );
};

export default AccordionItem;
