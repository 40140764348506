import icon from './icon.svg';
import {componentLabel} from '../utils/editor';

const loadBlock = (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('Form', icon),
    category: 'React Components',
    content: '<Webform />'
  });
}

export default loadBlock;
