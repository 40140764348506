export default (dc, {typeTab, typeTabContent, typeTabContents, typeTabContainer, style, ...config}) => {
  const type = config.typeTabs;

  const defTabs = [1, 2, 3].map(i => ({ type: typeTab }));
  const traits = [
    {
      full: 1,
      type: 'button',
      label: false,
      text: 'Add Tab',
      command: ed => {
        const sel = ed.getSelected();
        sel && sel.addTab();
      },
    }
  ];

  dc.addType(type, {
    model: {
      defaults: {
        name: 'Tabs',
        traits,
        components: [
          { type: typeTabContainer, components: defTabs },
          { type: typeTabContents },
        ],
        classes: ['nv-tabs'],
        ...config.tabsProps
      },
      init() {
        this.findTabs().map(this.__onTab);
        this.listenTo(this.getTabContainerType().components(), 'add', this.__onTab);
      },
      __onTab(tab, v, opts = {}) {
        !opts.avoidStore && !opts.temporary && tab.__initTab && tab.__initTab();
      },
      getTabContainerType() {
        return this.findType(typeTabContainer)[0];
      },
      getContentsType() {
        return this.findType(typeTabContents)[0] || this;
      },
      findTabs() {
        return this.findType(typeTab);
      },
      findContents() {
        return this.findType(typeTabContent);
      },
      addTab(content) {
        const cnt = this.getTabContainerType();
        cnt.append({
          type: typeTab,
          components: content,
        });
      },
    },
    view: {
      onRender({ el }) {
        initTabsComponent({
          classTabActive: 'tab-active',
          selectorTab: 'aria-controls',
          attrTabindex: 'tabIndex',
          attrSelected: 'ariaSelected',
          roleTab: '[role=tab]',
          roleTabContent: '[role=tabpanel]',
          rElement: el,
        });
      },
    }
  });
}
