import * as React from 'react';
import {MenuSelect, Panel} from 'react-instantsearch-dom';

export const FacetedSearchMenuSelect = ({label, attributeName}) => {
  return <Panel header={label} className={'fs-widget fs-widget--menu-select'}>
    <MenuSelect attribute={attributeName}  transformItems={items =>  items.filter(item => {
      return item.value !== '' || item.label !== '';
    }).map(item => {item.label.trim();return item;})} />
  </Panel>
}
