import * as React from 'react';
import Select from 'react-select';
import {SurveyQuestionDropdown} from 'survey-react-ui';

/**
 * https://github.com/JedWatson/react-select
 */
class CustomSelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
  }

  // Convert the `visibleChoices` array to a format supported by React Select
  get options() {
    if (this.question.choicesFromUrl && Array.isArray(this.question.choicesFromUrl)) {
      return this.question.choicesFromUrl.map(c => ({ value: c.value, label: c.text }));
    }

    if (this.question.visibleChoices && Array.isArray(this.question.visibleChoices)) {
      return this.question.visibleChoices.map(c => ({ value: c.value, label: c.text }));
    }

    return [];
  }

  // Retrieve an option object based on the question value
  get selectedOption() {
    return this.options.filter((o) => o.value === this.question.value);
  }

  // Set the question value to the selected option value
  onSelectChange = selectedOption => this.setValueCore(selectedOption.value);

  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div id={this.question.inputId} className={this.question.getControlClass()} disabled>
          {(this.question.displayValue || this.question.placeholder)}
        </div>);
    }

    const gjsStyles = (baseStyles, state) => {
      const gjsIframe = document.querySelector('.gjs-frame');
      if (gjsIframe) {
        const inlineStyles = document.querySelectorAll('[data-emotion="css"]');
        for (const style of inlineStyles) {
          gjsIframe.contentWindow.document.head.append(style);
        }
      }

      return baseStyles;
    };

    const isOtherOptionSelected = this.question.value === this.question.otherItem.value;
    const comment = isOtherOptionSelected ? this.renderOther(this.question.cssClasses) : null;

    return (
      <div className='sd-selectbase'>
        <div className="sv-dropdown_select-wrapper">
          <Select id={this.question.inputId}
                  value={this.selectedOption}
                  onChange={this.onSelectChange}
                  styles={{control: gjsStyles}}
                  className='nv-react-select'
                  classNamePrefix='nv-react-select'
                  options={this.options}
                  required={this.question.isRequired}/>
          {comment}
        </div>
      </div>
    );
  }
}

export default CustomSelect;
