import {loadAccordionComponent, loadAccordionItemComponent} from './component';
import {loadAccordionBlock, loadAccordionItemBlock} from './block';

const initAccordionComponent = (editor, opts) => {
  const accordionDefaults = {
    componentType: 'Accordion',
  };

  const accordionOptions = {...accordionDefaults, ...opts};

  loadAccordionComponent(editor, accordionOptions);
  loadAccordionBlock(editor, accordionOptions);

  const accordionItemDefaults = {
    componentType: 'AccordionItem',
  };

  const accordionItemsOptions = {...accordionItemDefaults, ...opts};

  loadAccordionItemComponent(editor, accordionItemsOptions);
  loadAccordionItemBlock(editor, accordionItemsOptions);
}

export default initAccordionComponent;
