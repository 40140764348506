import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { initPageNavigationScrolling } from './page-navigation/page-navigation-scrolling';
import { slugify } from './utils/slugify';

initPageNavigationScrolling();

/**
 * Mobile navigation component.
 *
 * @param {Object} props
 * @param {String} props.title
 * @param {Array} props.links
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const MobileNavigation = (props) => {
  const {title, links} = props;

  const [blockTitle, setBlockTitle] = useState('');

  useEffect(() => {
    setBlockTitle(title);
  }, [title]);

  const navItemsList = useRef();
  const toggleButton = useRef();

  const toggleNavigationState = () => {
    const expandedCSSClass = "expanded";
    if (navItemsList.current) {
      navItemsList.current.classList.toggle(expandedCSSClass);
    }
    if (toggleButton.current) {
      toggleButton.current.classList.toggle(expandedCSSClass);
    }
  }

  return (
    <div className="page-mobile-navigation">
      <button className="page-quick-links__header" ref={toggleButton}
              onClick={toggleNavigationState}>{blockTitle}</button>
      <ul className='page-quick-links__items' ref={navItemsList}>
        {links.length > 0 && links.map((link, idx) => {
          const {title, anchor, level} = link;
          const cssClasses = ['link-cta', 'page-quick-links__link'];
          cssClasses.push(`link-level-${level}`);

          return <li key={idx}>
            <a className={cssClasses.join(' ')}
               onClick={() => {
                 setBlockTitle(title);
                 toggleNavigationState();
               }}
               href={`#${anchor}`}>
              {title}
            </a>
          </li>
        })}
      </ul>
    </div>
  );
}

/**
 * Navigation portal.
 *
 * @param {Object} props
 * @param {String} props.title
 * @param {Array} props.links
 *
 * @returns {JSX.Element|React.ReactPortal}
 *
 * @constructor
 */
const PageNavigationWrapper = (props) => {
  const root = document.getElementById('page-mobile-nav-container');
  if (!root) {
    return <></>
  }

  const {title, links} = props;
  const element = <MobileNavigation title={title} links={links}/>
  return ReactDOM.createPortal(element, root);
}

const PAGE_BUILDER_SELECTOR = 'iframe.gjs-frame';

/**
 * Page navigation component.
 *
 * @param {Object} props
 * @param {String} props.title
 * @param {Boolean} props.use_numbering
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export default function PageNavigation(props) {
  const isPageBuilder = !!document.querySelector(PAGE_BUILDER_SELECTOR);
  const tocItems = isPageBuilder ? [] : createTOCItems(document, isPageBuilder);

  const {title, use_numbering} = props;
  const [blockTitle, setBlockTitle] = useState('');
  const [links, setLinks] = useState(tocItems);
  const [selectedItemIdx, setSelectedItemIdx] = useState(0);

  useEffect(() => {
    if (isPageBuilder) {
      const observer = new MutationObserver(() => {
        const contentEl = document.querySelector("iframe").contentWindow.document;
        if (contentEl) {
          const items = createTOCItems(contentEl, true);
          setLinks(items);
        }
      })
      observer.observe(document, {subtree: true, childList: true})
    }

    if (window.location.hash) {
      const selected = document.getElementById(window.location.hash.substring(1));
      if (selected) {
        setTimeout(() => {
          selected.scrollIntoView({behavior: 'smooth'});
        }, 1000)
      }
    }
  }, []); // Important: no deps -> only run once

  useEffect(() => {
    setBlockTitle(title ? title : 'On this page');
  }, [title]);

  const handleMenuToggle = (event, idx) => {
    event.stopPropagation();
    event.preventDefault()
    const li = document.querySelector('.page-quick-links li[data-idx="' + idx + '"]');
    li.classList.toggle('active');
    const childElements = document.querySelectorAll('.page-quick-links li[data-parent="' + idx + '"]');
    childElements.forEach(value => {
      value.classList.toggle('hidden');
    });
  }

  return (
    <div className="page-quick-links page-navigation">
      <p className="p--small page-quick-links__header">{blockTitle}</p>
      <ul>
        {links.length > 0 && links.map((link, idx) => {
          const {title, anchor, level, hasSubMenu, currentNumber, parent, isAccordionLink} = link;
          const cssClasses = ['page-quick-links__link'];
          cssClasses.push(`link-level-${level}`);
          if (idx === selectedItemIdx) {
            cssClasses.push('active');
          }

          if (hasSubMenu) {
            cssClasses.push('with-sub-menu');
            return <li className='page-quick-links__list-item'
                       key={idx}
                       data-lvl={level}
                       data-idx={idx}
                       data-sub-menu={hasSubMenu}
                       onClickCapture={(e) => {
                         handleMenuToggle(e, idx);
                       }}>
              <a className={cssClasses.join(' ')}
                 data-sub-menu={hasSubMenu}
                 href={`#${anchor}`}>
                {use_numbering === true && level === 1 && <span>{currentNumber}. </span>} {title}
              </a>
              {hasSubMenu && <button className='menu-toggler'></button>}
            </li>
          }

          return <li className='page-quick-links__list-item'
                     key={idx}
                     data-idx={idx}
                     data-lvl={level}
                     data-parent={parent}>
            <a className={cssClasses.join(' ')}
               data-sub-menu={hasSubMenu}
               onClick={() => {
                 if (isAccordionLink) {
                   const linkElement = document.getElementById(anchor);
                   const accordionButton = linkElement.closest('button');
                   if (accordionButton && accordionButton.classList.contains('collapsed')) {
                     accordionButton.click();
                   }
                 }
                 setSelectedItemIdx(idx)
               }}
               href={`#${anchor}`}>
              {use_numbering === true && level === 1 && <span>{currentNumber}.</span>} {title}
            </a>
          </li>
        })}
      </ul>
      <PageNavigationWrapper title={blockTitle} links={links}/>
    </div>
  );
}

/**
 * Queries a parent element for toc-items and returns an array of TOC objects.
 * It also modifies the dom tree `.toc-items` found within the parent element
 * and adds an ID property that matches the heading slug. This provides
 * link clicking anchor functionality.
 * @param {Element } parentEl element to search for `.toc-items`
 * @param {boolean} isPageBuilder flag to notify if in page builder mode (dom will not be modified if true)
 * @return Object[]
 */
function createTOCItems(parentEl, isPageBuilder) {
  if (!parentEl) {
    return [];
  }
  let tocItems = [];
  let currentNumber = 1;
  parentEl
    .querySelectorAll('.toc-item')
    .forEach((tocItemEl) => {
      const supportedElements = ['h1', 'h2', 'h3', 'ul', 'p', 'div'];
      const elementTagName = tocItemEl.tagName.toString().toLowerCase();
      if (!supportedElements.includes(elementTagName)) {
        return;
      }

      const link = {title: '', anchor: '', level: 1, currentNumber: currentNumber};
      link.title = tocItemEl.innerText.trim();
      link.hasSubMenu = false;
      if (tocItemEl.title) {
        link.title = tocItemEl.title;
      }

      // Workaround for accordion headings in Safari.
      if (!link.title) {
        const div = document.createElement('div');
        div.innerHTML = tocItemEl.innerHTML;
        link.title = div.innerText;
      }

      const elementID = slugify(link.title);
      const anchorId = `section-${elementID}`;
      link.anchor = anchorId;

      switch (elementTagName) {
        case 'ul':
        case 'p':
        case 'h3':
        case 'div':
          link.level = 2;
          break;
        default:
          currentNumber += 1;
          break;
      }

      if (tocItemEl.classList.contains('toc-item-accordion')) {
        link.isAccordionLink = true;
      }

      // do not modify DOM in page builder, or
      // it will be captured when the page is saved
      if (!isPageBuilder && !tocItemEl.dataset.anchorId) {
        const tocAnchorEl = document.createElement('div');
        tocAnchorEl.id = anchorId;
        tocAnchorEl.classList.add('toc-item-anchor');
        tocItemEl.parentElement.insertBefore(tocAnchorEl, tocItemEl);
        tocItemEl.dataset.anchorId = anchorId;
      }

      tocItems.push(link);
    });

  let parent = 0;
  if (tocItems.length > 0) {
    tocItems = tocItems.map((item, idx) => {
      let nextLevel = 0;
      if (item.level === 1) {
        parent = idx;
      }

      if (idx + 1 < tocItems.length) {
        nextLevel = tocItems[idx + 1].level;
      }

      if (nextLevel > item.level) {
        item.hasSubMenu = true;
      }

      item.parent = parent;

      return item;
    });
  }

  return tocItems
}
