function chinaPIPLTermsCssClasses(survey, options) {
  if('china-pipl-terms' === options.question.getType()) {
    options.cssClasses.mainRoot = `${options.cssClasses.root} china-pipl-terms`;
  }
}

export const ChinaPIPLTerms = {
  name: 'china-pipl-terms',
  title: 'China PIPL terms',
  elementsJSON: [
    {
      type: 'boolean',
      name: 'pipl1',
      title: '本人知悉且同意 NVIDIA Corporation 基于调研、活动组织的目的对本人的上述信息的收集和处理，并已经阅读并同意 NVIDIA 隐私政策。',
      isRequired: true,
      defaultValue: false,
      validators: [
        {
          type: 'expression',
          text: "确认同意上述条款之后，您才可以提交表单。",
          expression: '{china-pipl-terms.pipl1} = true',
        }
      ]
    },
    {
      type: 'boolean',
      name: 'pipl2',
      title: '本人知悉且同意，因调研、活动组织的必须，以及相对应的 NVIDIA 内部管理和系统操作的需要，上述信息会被传输到位于美国的 NVIDIA Corporation 按照符合 NVIDIA 隐私政策的方式进行存储，您可以通过发送邮件至 privacy@nvidia.com 进行联系以解决相关问题，实现可适用的数据保护法所规定的权利。',
      isRequired: true,
      defaultValue: false,
      validators: [
        {
          type: 'expression',
          text: "确认同意上述条款之后，您才可以提交表单。",
          expression: '{china-pipl-terms.pipl2} = true',
        }
      ]
    }
  ],
  onLoaded({survey}) {
    if (survey) {
      survey.onUpdateQuestionCssClasses.add(chinaPIPLTermsCssClasses);
    }
  }
}
