import galleryThumbnailsIcon from './gallery-thumbnails.svg';
import { componentLabel } from '../utils/editor';

const loadBlock = (editor, opts) => {
  editor.BlockManager.add(opts.componentType, {
    label: componentLabel('On-Demand gallery widget', galleryThumbnailsIcon),
    category: 'React Components',
    activate: true,
    select: true,
    content: '<GalleryWidget></GalleryWidget>'
  });
}
export default loadBlock;
