import * as React from "react";

export default function ({email}) {
  let loginURL = new URL(`${location.origin}/login`);

  if (email) {
    let parts = email.split('@');
    let username = parts[0];
    let domain = parts[1];
    let encodedUsername = encodeURIComponent(username);

    loginURL.searchParams.append('login_hint', `${encodedUsername}@${domain}`);
  }

  return (
    <div id='application-status' className='card'>
      <div className='card-body'>
        <div id='app-status'>
          <div className='card-text mb-0'>
            <h4 className='card-title mb-0'>{I18n.t('reauthenticate')}</h4>
          </div>
          <div className="app-link d-flex align-items-center">
            <a className="link-cta text-transform-unset" href={loginURL.toString()}>
              {I18n.t('continue')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
