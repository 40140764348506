import * as React from 'react';
import classnames from 'classnames';
import { componentLayouts } from '../Recommender';

function CtaTarget(url) {
  let target;
  try {
    target = new URL(url).hostname === location.hostname ? '_self' : '_blank';
  } catch (e) {
    target = '_self';
  }
  return target;
}

/**
 * Recommendation card.
 *
 * @param {Object} props
 * @param {String} props.title
 * @param {String} props.pretitle
 * @param {String} props.url
 * @param {String} props.imageUrl
 * @param {String} props.layout
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const Recommendation = (props) => {
  const {
    title,
    pretitle,
    subtitle = '',
    excerpt = '',
    url,
    imageUrl,
    layout,
    ctaText = ''
  } = props;

  let ctaTarget = CtaTarget(url);

  const isNewTheme = !!document.querySelector('.dz-new-theme');
  let rootCss = {
    'col-xl-3': layout === componentLayouts.fourColumns,
    'col-xl-4': layout === componentLayouts.threeColumns,
    'col-lg-4': true,
    'col-md-4': true,
    'col-sm-12': true,
    'js-col': true,
    'nv-recommendation': true,
  };

  if (isNewTheme) {
    rootCss = {
      'col-xl-3': layout === componentLayouts.fourColumns,
      'col-xl-4': layout === componentLayouts.threeColumns,
      'col-lg-4': layout === componentLayouts.threeColumns,
      'col-lg-3': layout === componentLayouts.fourColumns,
      'col-md-4': true,
      'col-sm-12': true,
      'js-col': true,
      'nv-recommendation': true,
    };
  }
  const css = {
    root: classnames(rootCss),
    link: classnames({
      'link-cta': true,
      'pseudo-stretched-link': true,
      'link--no-text': ctaText === undefined || !ctaText || `${ctaText}`.trim() === '',
    })
  };
  const titleAttribute = `${pretitle} / ${title}`;

  return (
    <div className={css.root}>
      <div className='nv-recommendation__card'>
        {imageUrl && <div className='nv-recommendation__image'>
          <img src={imageUrl} alt={title} loading='lazy'/>
        </div>}
        <div className='nv-recommendation__body'>
          {pretitle && (
            <div className='nv-recommendation_pretitle'><p className='p--small mb-0'>{pretitle ?? ''}</p></div>
          )}
          <div className='nv-recommendation__content'>
            <div className='nv-recommendation__title'>
              <h3 className='h--smallest mt-0 mb-0'>{title ?? ''}</h3>
            </div>
            {excerpt && (
              <div className='nv-recommendation__excerpt'><p className='mb-0'>{excerpt}</p></div>
            )}
          </div>
          {subtitle && (
            <div className='nv-recommendation__subtitle'>{subtitle}</div>
          )}
          <div className='nv-recommendation__links'>
            <a href={url} target={ctaTarget} className={css.link} title={titleAttribute}>{ctaText ? ctaText : ''}</a>
          </div>
        </div>
      </div>
    </div>
  );
};
