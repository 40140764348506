import * as React from 'react';
import {addError} from '../../../packs/tracing';

export const Chevron = () => {
  return <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><path d="m9 22.5 9-9 9 9" stroke="#666" strokeWidth="2"/></svg>
}

export const ChevronSmall = () => {
  return <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m18 9-6 6-6-6" stroke="#5e5e5e" strokeWidth="2"/></svg>
}

export const IconFilter = () => {
  return <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 1H10.5M2.5 4H8.5M4.5 7H6.5" stroke="black" strokeWidth="2"/>
  </svg>
}

export const IconClose = () => {
  return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.5L16.5 16.5M16.5 1.5L1.5 16.5" stroke="black" strokeWidth="1.5"/>
  </svg>
};

/**
 * @param {boolean} status
 * @returns {JSX.Element}
 * @constructor
 */
export const StatusIcon = ({status}) => {
  if (status) {
    return <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5L5 9L13 1" stroke="black"/>
    </svg>
  }
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L11 11M11 1L1 11" stroke="black"/>
  </svg>
}

/**
 * Toggles filter CSS classes.
 *
 * @param {Element} e
 * @param {String} defaultCSSClass
 */
export const toggleFilterState = (e, defaultCSSClass = 'collapsed') => {
  if (e === undefined) {
    return;
  }

  const cssExpendedClass = defaultCSSClass;
  if (e.classList.contains(cssExpendedClass)) {
    e.classList.remove(cssExpendedClass);
    return;
  }

  e.classList.add(cssExpendedClass)
}

export const cleanLabel = (value) => {
  if (typeof value === 'string') {
    return value.replace('>', '/');
  }

  return value;
}

/**
 * Fetches collection details.
 *
 * @param {String} protocol
 * @param {String} host
 * @param {String} port
 * @param {String} collectionName
 * @param {String} ApiKey
 * @returns {Promise<any>}
 */
export const fetchCollectionDetails = async (protocol, host, port, collectionName, ApiKey) => {
  let url;
  try {
    url = new URL(`${protocol}://${host}:${port}/collections/${collectionName}`);
  } catch (e) {
    handleRequestError(e);
    return;
  }
  const options = {headers: {'x-typesense-api-key': ApiKey, 'Content-Type': 'application/json'}};
  const data = await fetch(url, options);
  if (data.status !== 200) {
    let text = await data.text();
    text = `Collection name: ${collectionName}; Response: ${text}`
    throw new Error(text)
  }

  return await data.json();
};

export const handleRequestError = (e) => {
  addError('facted-search', e)
};

export const AlertMessage = ({title, type, children}) => {
  const cssClasses = ['nv-admin-alert'];
  if (type === 'error') {
    cssClasses.push('nv-admin-alert--error')
  }

  return <div role="alert" className={cssClasses.join(' ')}>
    <div className="nv-admin-alert__title">
      {title}
    </div>
    <div className="nv-admin-alert__content">
      {children}
    </div>
  </div>
}

export const mappingHasProperty = (mapping, hit, property, skipHitCheck = false) => {
  if (skipHitCheck) {
    return mapping.hasOwnProperty(property) && mapping[property];
  }
  return mapping.hasOwnProperty(property) && hit[mapping[property]]
}

/**
 * Converts options string to object. E.g: key1:value;key2:value2
 *
 * @param {String} str
 *
 * @returns {Object}
 */
export const parseOptionsString = (str) => {
  const obj = {};
  str.split(';').forEach((record) => {
    const [key, val] = record.split(':');
    obj[key] = !!parseInt(val);
  });
  return obj;
}
