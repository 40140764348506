import loadBlock from './block';
import loadComponent from './component';
import onLoad from './on-load';
import commands from './commands';

/**
 * Icon component entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'nv-icon',
    category: 'Media',
    commands: {
      openIconSelectorModal: 'open-icon-selector-modal',
    },
    icons: [
      'chevron-right',
      'download',
      'file',
      'newspaper',
      'file-pdf',
      'play',
      'link',
      'lock',
      'external-link',
      'check'
    ]
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  commands(editor, options);
  onLoad(editor, options);
  loadComponent(editor, options);
  loadBlock(editor, options);
}
