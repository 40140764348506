import memoPadIcon from './icons/memo-pad.svg'
import listDropdownIcon from './icons/list-dropdown.svg'
import {componentLabel} from "../utils/editor";

/**
 * GrapesJS blocks definitions.
 */

export const loadAccordionBlock = (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('Accordion', listDropdownIcon),
    category: 'React Components',
    activate: true,
    select: true,
    content: '<Accordion />'
  });
}

export const loadAccordionItemBlock = (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('Accordion item', memoPadIcon),
    category: 'React Components',
    activate: true,
    select: true,
    content: '<AccordionItem />'
  });
}
