import {componentCommandName, componentKey, componentType, svg} from './editor';
import CustomHtml from '../CustomHtml';
import {userIsAdmin} from '../utils/user';

export function loadComponent(editor, opts = {}) {
  let timedInterval;
  const {toolbarBtnCustomCode} = opts;

  const canEdit = userIsAdmin();

  editor.Components.addType(componentType, {
    extend: 'react-component',
    extendFnView: ['init'],
    model: {
      defaults: {
        component: CustomHtml,
        'custom-name': 'Custom HTML',
        editable: canEdit,
        draggable: canEdit,
        droppable: canEdit
      },
      toHTML() {
        let props = JSON.stringify({
          preview: false,
        });
        props = this.escapeHTML(props);
        let klass = this.get('type');
        return `
        <div class="custom-html-wrapper">
          <div class="custom-html-wrapper__code">${this.get(componentKey)}</div>
          <div data-react-class="${klass}" data-react-props="${props}"></div>
        </div>
        `;
      },
      init() {
        this.listenTo(this, `change:${componentKey}`, this.onCustomCodeChange);
        const initialCode = this.get(componentKey) || opts.placeholderContent;
        !this.components().length && this.components(initialCode);
        const toolbar = this.get('toolbar');
        const id = 'custom-code';

        // add the custom code toolbar button if requested and if it's not already present
        if (toolbarBtnCustomCode && !toolbar.filter(tlb => tlb.id === id).length) {
          toolbar.unshift({
            id,
            command: componentCommandName,
            label: svg,
            ...toolbarBtnCustomCode
          });
        }

        if (!canEdit) {
          while (toolbar.length > 0) {
            toolbar.pop();
          }
        }
      },
      onCustomCodeChange() {
        this.components(this.get(componentKey));
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      init() {
        this.listenTo(this.model.components(), 'add remove reset', this.onComponentsChange);
        this.onComponentsChange();
      },
      onComponentsChange() {
        // Lock child components.
        // https://grapesjs.com/docs/api/component.html#component
        this.model.components().forEach((innerComponent) => {
          innerComponent.props().layerable = false;
          innerComponent.props().selectable = false;
        });

        timedInterval && clearInterval(timedInterval);
        timedInterval = setTimeout(() => {
          const {model} = this;
          let droppable = 1;
          let preview = true;

          model.set({preview});
          model.set({droppable});
        }, 0);
      },
      onActive() {
        const target = this.model;
        this.em.get('Commands').run(componentCommandName, {target});
      },
    },
    isComponent: (el) => {
      return el.tagName === 'CUSTOMHTML';
    }
  });
}
