import FeedAggregator from '../FeedAggregator';
import {FeedAggregatorLayout} from './editor';

/**
 * GrapesJS component definition.
 *
 * @param editor
 * @param options
 */
export default function loadComponent(editor, options) {
  const {componentCommandName} = options;
  const componentDefinition = {
    extend: 'react-component',
    model: {
      init() {
      },
      defaults: {
        components: [],
        component: FeedAggregator,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          sources: '',
        },
        traits: [
          {
            label: 'Items to display',
            name: 'items_to_display',
            type: 'number',
            placeholder: `${options.minItemsToDisplay} - ${options.maxItemsToDisplay} (default: 4)`,
            min: options.minItemsToDisplay,
            max: options.maxItemsToDisplay,
            step: 1
          },
          {
            label: 'Show date',
            type: 'checkbox',
            name: 'show_date'
          },
          {
            label: 'Show excerpt',
            type: 'checkbox',
            name: 'show_excerpt'
          },
          {
            label: 'CTA text',
            placeholder: 'Read more',
            type: 'text',
            name: 'cta_text'
          },
          {
            type: 'select',
            name: 'layout',
            options: [
              FeedAggregatorLayout.three_cols,
              FeedAggregatorLayout.four_cols,
            ],
            value: FeedAggregatorLayout.four_cols.id
          },
          {
            type: 'run-command',
            buttonLabel: 'Manage sources',
            commandName: componentCommandName
          }
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === options.componentType.toUpperCase();
    }
  };
  editor.Components.addType(options.componentType, componentDefinition);
}
