import { componentLabel } from '../utils/editor';
import slider from './slider.svg';

/**
 * Registers the component to the block manager.
 *
 * @param {Object} editor
 * @param {String} componentType
 * @param {String} category
 */
export default (editor, { componentType, category }) => {
  editor.BlockManager.add(componentType, {
    label: componentLabel('Image comparison slider', slider),
    category: category,
    content: {
      type: componentType
    },
  });
}
