import {sanitizeEditorOption} from "../utils/editor";
import {TraitTypeOptionsSet} from "./options-set";

const AddDescriptionTrait = (editor) => {
  const traitConfig = {
    noLabel: true,
    createInput({trait}) {
      const label = trait.get('text') || '';

      const el = document.createElement('div');
      el.innerHTML = `<div class="gjs-trait-description">${label}</div>`;
      return el;
    },
  };

  editor.TraitManager.addType('description', traitConfig);
};

export default AddDescriptionTrait;
