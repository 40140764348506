import loadBlock from './block';
import loadComponent from './component';

/**
 * Waterfall entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'Waterfall',
    componentName: 'Waterfall',
    category: 'React Components',
    css: {
      root: ['row', 'nv-waterfall'],
      imageColumn: ['col-lg-5', 'col-md-12', 'col-sm-12', 'image-column', 'js-image-column'],
      textColumn: ['col-lg-7', 'col-md-12', 'col-sm-12', 'text-column', 'js-text-column'],
    },
    defaultProps: {
      removable: true,
      selectable: true,
      hoverable: true,
    }
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
}
