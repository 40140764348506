import { Serializer } from 'survey-core';
import envelopesBulkIcon from './icons/envelopes-bulk.svg';
import { registerCustomIcon } from './icons';
import { getUserProfile } from '../utils/user';

registerCustomIcon('envelopes-bulk', envelopesBulkIcon);

const DEFAULT_OPT_IN_SENTENCE = 'Send me the latest developer news, announcements, and more from NVIDIA. I can unsubscribe at any time.';

function prefillFields(questions, userData) {
  const fieldNames = ['first_name', 'last_name', 'email', 'location'];

  questions.forEach(q => {
    if (!fieldNames.includes(q.name)) {
      return;
    }
    switch (q.getType()) {
      case 'text':
        q.value = userData[q.name] ? userData[q.name] : q.value;
        break;
      case 'dropdown':
        q.value = (q.name === 'location' && userData['country']) ? userData['country'].toUpperCase() : q.value;
        break;
    }
  });
}

export const MicroOptIn = {
  name: 'micro-opt-in',
  title: 'Micro opt-in',
  iconName: 'icon-envelopes-bulk',
  tmpStorage: {},
  onInit() {
    Serializer.addProperty('micro-opt-in', {
      name: 'microOptInSentence',
      type: 'text',
      isLocalizable: true,
      category: 'general',
      displayName: 'Opt-in sentence',
    });

    this.tmpStorage['currentUser'] = getUserProfile();
  },
  onLoaded(question) {
    const {currentUser} = this.tmpStorage;
    if (currentUser) {
      prefillFields(question.getNestedQuestions(), currentUser);
    }

    const cp = question.contentPanel;
    const optInQuestion = cp.getQuestionByName('opt_in');

    let localizedMicroOptInSentence = question.getLocalizableString('microOptInSentence');
    if (!localizedMicroOptInSentence.values.default) {
      localizedMicroOptInSentence.values = {
        default: DEFAULT_OPT_IN_SENTENCE,
      }
    }

    optInQuestion.choices = [{ value: 1, text: localizedMicroOptInSentence.values }];
  },
  onPropertyChanged(question, propertyName, newValue) {
    if (propertyName === 'microOptInSentence') {
      const cp = question.contentPanel;
      const optInQuestion = cp.getQuestionByName('opt_in');
      optInQuestion.choices = [{ value: 1, text: newValue || DEFAULT_OPT_IN_SENTENCE }];
    }
  },
  elementsJSON: [
    {
      type: 'text',
      name: 'first_name',
      title: {
        "default": 'First name',
        "zh-cn": '名字',
      },
      errorLocation: 'bottom',
      isRequired: true,
    }, {
      type: 'text',
      name: 'last_name',
      title: {
        "default": 'Last name',
        "zh-cn": '姓氏',
      },
      errorLocation: 'bottom',
      isRequired: true,
      startWithNewLine: false,
    }, {
      type: 'text',
      name: 'email',
      title: {
        "default": 'Email',
        "zh-cn": '电子邮箱',
      },
      errorLocation: 'bottom',
      inputType: 'email',
      isRequired: true,
    }, {
      type: 'dropdown',
      name: 'location',
      title: {
        "default": 'Location',
        "zh-cn": '地区',
      },
      errorLocation: 'bottom',
      isRequired: true,
      choicesByUrl: {
        url: '/api/countries', titleName: 'label',
      },
      startWithNewLine: false,
    }, {
      type: 'checkbox',
      name: 'opt_in',
      titleLocation: 'hidden',
      errorLocation: 'bottom',
      choices: []
    }, {
      "type": "html",
      "name": "privacy-policy",
      "html": "<a href='https://www.nvidia.com/en-us/about-nvidia/privacy-policy/?deeplink=visiting-our-website' target='_blank'>NVIDIA Privacy Policy</a>"
    }
  ]
}
