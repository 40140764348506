/**
 * Sidebar component.
 *
 * @param {Object} dc
 *   Dom components manager.
 * @param {Object} config
 *   Component config.
 */
export default (dc, config) => {
  dc.addType(config.subComponents.sidebar.type, {
    model: {
      defaults: {
        name: config.subComponents.sidebar.name,
        ...config.defaultProps,
        classes: config.css.sidebar,
        components: [],
      }
    }
  });
}
