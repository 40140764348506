import React from 'react';
import ReactDOM from "react-dom";
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import {AutocompleteWidget} from './react/AutocompleteWidget';

// Trait type.
export const TraitTypeAutocomplete = 'autocomplete';

// Supported models.
export const AutocompleteModel = {
  form: 'form',
  program: 'program',
};

/**
 * Returns attribute value by name.
 *
 * @param {Object} component
 * @param {String} attributeName
 * @returns {undefined|string|Number|boolean}
 */
const componentAttributeByName = (component, attributeName) => {
  if (!attributeName) {
    return undefined;
  }
  const attributes = component.getAttributes();
  return attributes[attributeName] ? attributes[attributeName] : undefined;
};

const buildStorageName = (trait, type) => {
  let baseName = trait.get('name');
  switch (type) {
    case 'id':
      return baseName;
    case 'entity_name':
      return `${baseName}_entity_name`;
    default:
      throw new Error(`Unexpected name type given: ${type}`);
  }
};

/**
 * Returns form storage HTML element.
 *
 * @param {String} name
 *   Element name.
 * @param {Array} classes
 *   CSS classes.
 * @returns {HTMLInputElement}
 */
const createStorageElement = (name, classes = []) => {
  classes.unshift('js-gjs-autocomplete-value');
  classes.push('gjs-visually-hidden');
  const el = document.createElement('input');
  el.type = 'text';
  el.name = name;
  el.className = classes.join(' ');
  return el;
}

export const AddAutocompleteTrait = (editor) => {
  const traitConfig = {
    eventCapture: ['input'],
    noLabel: true,
    /**
     * Creates trait inputs.
     * @param {Object} trait
     * @param {Object} component
     * @param {Function} component.addAttributes
     * @returns {HTMLDivElement}
     */
    createInput({trait, component}) {
      const model = trait.get('model') || '';
      const isRequired = trait.get('required') || false;
      const placeholder = trait.get('placeholder') || '';
      const name = trait.get('name') || '';
      if (!name || name.length === 0) {
        const message = 'Autocomplete widget initialization failed. Trait name not defined.';
        const errorElement = document.createElement('div');
        errorElement.innerHTML = message;
        errorElement.className = 'gjs-autocomplete-widget--not-initialised';
        return errorElement;
      }

      const widgetContainer = document.createElement('div');
      widgetContainer.className = 'gjs-autocomplete-widget'

      const reactRoot = document.createElement('div');
      reactRoot.className = 'gjs-react-autocomplete';
      widgetContainer.append(reactRoot);

      // "Form id" storage.
      const entityIdStorage = createStorageElement(name, [
        'js-gjs-autocomplete-widget__entity-id',
        'gjs-autocomplete-widget__entity-id'
      ]);
      const entityIdDefaultValue = componentAttributeByName(component, name);
      entityIdStorage.value = entityIdDefaultValue === undefined ? '' : entityIdDefaultValue;
      widgetContainer.append(entityIdStorage);

      // "Form name" storage.
      const entityNameElementName = buildStorageName(trait, 'entity_name')
      const entityNameStorage = createStorageElement(entityNameElementName, [
        'js-gjs-autocomplete-widget__entity-name',
        'gjs-autocomplete-widget__entity-name'
      ]);
      const entityNameDefaultValue = componentAttributeByName(component, entityNameElementName);
      entityNameStorage.value = entityNameDefaultValue === undefined ? '' : entityNameDefaultValue;
      widgetContainer.append(entityNameStorage);

      /** @param {Array} values */
      const onChangeHandler = (values) => {
        const entityNameAttr = {}, attr = {};
        const value = (values.length && values[0]?.id) ? values[0] : {id: '0', text: ''};
        const {id, text} = value;

        attr[name] = entityIdStorage.value = id;
        component.addAttributes(attr);

        entityNameAttr[entityNameElementName] = entityNameStorage.value = text
        component.addAttributes(entityNameAttr);
      };

      ReactDOM.render(<AutocompleteWidget
        model={model}
        entityId={entityIdDefaultValue}
        entityName={entityNameDefaultValue}
        placeholder={placeholder}
        onChangeHandler={onChangeHandler}
        required={isRequired}/>, reactRoot);
      return widgetContainer
    },
    /**
     * OnUpdate event handler.
     *
     * @param {Element} elInput
     * @param {Object} component
     * @param {Function} component.getAttributes
     * @param {Object} trait
     */
    onUpdate({elInput, component, trait}) {
      const storageTypes = [
        ['id', '.js-gjs-autocomplete-widget__entity-id'],
        ['entity_name', '.js-gjs-autocomplete-widget__entity-name']
      ]

      for (const item of storageTypes) {
        /** @var {HTMLInputElement} storageElement */
        const storageElement = elInput.querySelector(item[1]);
        if (!storageElement) {
          continue;
        }
        const nameKey = buildStorageName(trait, item[0]);
        const value = componentAttributeByName(component, nameKey);
        storageElement.value = (undefined === value) ? '' : value;
      }
    },
  };

  editor.TraitManager.addType(TraitTypeAutocomplete, traitConfig);
};
