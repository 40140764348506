import { loadComponent } from './component';
import { loadBlock } from './block';
import { layouts, mappingTypes } from './data-mapping';

export const componentType = 'FacetedSearch';

export function initFacetedSearchComponent(editor, opts) {
  const defaults = {
    layouts: [
      {id: layouts.standard, name: 'Standard'},
      {id: layouts.compact, name: 'Compact'},
    ],
    viewModes: [
      {id: mappingTypes.card, name: 'Card'},
      {id: mappingTypes.accordion, name: 'Accordion'},
    ],
    cardOptions: [
      {id: 'hasImagePadding', default: 0, label: 'Image padding'},
      {id: 'isClickableCard', default: 0, label: 'Clickable card'},
      {id: 'truncatePretitle', default: 0, label: 'Truncate pretitle'},
    ],
  };
  const options = {...defaults, ...opts};
  loadComponent(editor, options);
  loadBlock(editor, options);
}
