import {componentType} from './editor';

export function loadBlock(editor, opts = {}) {
  editor.BlockManager.add(componentType, {
    label: '<svg viewBox="0 0 24 24" width="48"><path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path></svg><div>Custom HTML</div>',
    category: 'React Components',
    activate: true,
    select: true,
    content: '<CustomHtml></CustomHtml>'
  });
}
