import loadComponent from './component';
import loadBlock from './block';

const initWebformComponent = (editor, opts) => {
  const defaults = {
    optionsEndpoint: '/nv/admin/forms/all_options',
    componentType: 'Webform',
  };
  const options = {...defaults, ...opts};
  loadComponent(editor, options);
  loadBlock(editor, options);
};

export default initWebformComponent;
