import { registerCustomIcon } from './icons';
import atIcon from './icons/at.svg';

registerCustomIcon('at', atIcon);

export const Email = {
  name: "email",
  title: "Email",
  iconName: 'icon-at',
  defaultQuestionTitle: "Email",
  questionJSON: {
    "type": "text",
    "placeholder": "",
    "title": "Email",
    "inputType": "email",
  },
  inheritBaseProps: true
};
