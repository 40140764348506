/**
 * Component commands.
 *
 * @param {Object} editor
 * @param {Object} options
 */
export default (editor, options = {}) => {
  const commands = editor.Commands;
  commands.add(options.commands.openIconSelectorModal, {
    run(editor, sender, opts = {}) {
      const iconsHtml = options.icons.map(i => `
                <div class="gjs-fa-icon-wrapper" data-component-id="${opts.model.cid}" data-icon='${i}'>
                  <i class='nv-icon' data-icon='${i}'></i>
                </div>
              `);
      editor.Modal.open({
        title: 'Select an icon',
        content: `<div class="gjs-icon-selector">${iconsHtml.join('')}</div>`,
      }).onceClose(() => this.stopCommand());
    },
    stop(editor) {
      editor.Modal.close();
    },
  });
};
