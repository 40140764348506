import {componentType} from './editor';

const loadBlock = (editor, opts) => {
  editor.BlockManager.add(componentType, {
    label: '<svg width="48" height="48" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M4 1.5H12M4 9.5H12M4 5.5H12M0.5 0.5H2.5V2.5H0.5V0.5ZM0.5 8.5H2.5V10.5H0.5V8.5ZM0.5 4.5H2.5V6.5H0.5V4.5Z" stroke="#fefefe"></path></svg><div>Page navigation</div>',
    category: 'React Components',
    activate: true,
    select: true,
    content: '<PageNavigation></PageNavigation>'
  });
}
export default loadBlock;
