export const role = 'tab';

export default (dc, {defaultModel, typeTabs, selectorTab, editor, ...config}) => {
  const prevSelectorTab = 'data-prev';
  const traits = [
    {
      type: 'text',
      name: 'tab-id',
      label: 'ID'
    },
    {
      type: 'description',
      text: 'ID must be unique, if set.',
    },
  ];
  const getPrevCntId = cmp => cmp.getAttributes()[prevSelectorTab];

  dc.addType(config.typeTab, {
    model: {
      defaults: {
        name: 'Tab',
        draggable: `[data-gjs-type="${config.typeTabContainer}"]`,
        attributes: { role },
        components: config.templateTab,
        classes: config.classTab,
        traits,
        ...config.tabProps
      },

      init() {
        this.on('removed', this.__onRemove);
        this.on('change:attributes:tab-id', this.__onChangeTabId);
      },

      __initTab() {
        if (this.tabContent) {
          return;
        }
        let content = this.getTabContent();

        // In case the content was found via previous id (cloned from parent)
        if (content && getPrevCntId(content)) {
          const id = content.getId();
          const tabId = this.getId();
          content.addAttributes({ id, 'aria-labelledby': tabId, hidden: true });
          content.removeAttributes(prevSelectorTab);
          this.addAttributes({ [selectorTab]: id, id: tabId });
        }

        // If the tab content was found I'll attach it to the tab model
        // otherwise I'll create e new one
        if (!content) {
          const tabs = this.getTabsType();
          const cnts = tabs.getContentsType();
          content = cnts.append({
            type: config.typeTabContent,
            components: config.templateTabContent(this),
          })[0];
          const id = content.getId();
          const tabId = this.getId();
          content.addAttributes({ id, 'aria-labelledby': tabId, hidden: true });
          this.addAttributes({ [selectorTab]: id, id: tabId });
          this.tabContent = content;
        }

        this.tabContent = content;
      },

      __onChangeTabId() {
        const attrs = this.getAttributes();
        const newAnchor = attrs['tab-id'];
        const currentElementID = attrs['aria-controls'];
        const tabs = this.getTabsType();
        if (!tabs) {
          console.warn('Tabs not found.');
          return;
        }
        const tabsContents = tabs.findContents();
        if(!tabsContents) {
          console.warn('Tabs content not found.');
          return;
        }
        let selectedTabContent = tabsContents.filter(c => {
          const cAttrs = c.getAttributes();
          return cAttrs && cAttrs['id'] === currentElementID;
        });

        if (selectedTabContent.length) {
          selectedTabContent = selectedTabContent.pop();
          selectedTabContent.addAttributes({'aria-labelledby': newAnchor, 'id': newAnchor, 'hidden': false});
          this.addAttributes({'aria-controls': newAnchor});
        }else {
          console.warn('Failed to change anchor');
        }
      },

      __onRemove() {
        const content = this.getTabContent();
        content && content.remove();
        this.getTabsType().trigger('rerender')
      },

      getTabsType() {
        return this.closestType(typeTabs);
      },

      getTabContent() {
        const id = this.getAttributes()[selectorTab];
        const tabs = this.getTabsType();
        if (!tabs || !id) {
          return;
        }
        const contents = tabs.findContents();
        return contents.filter(c => c.getId() === id || getPrevCntId(c) === id)[0];
      },

      clone(opts = {}) {
        const fromParent = opts._inner;
        if (fromParent) {
          const tabCont = this.getTabContent();
          tabCont && tabCont.addAttributes({ [prevSelectorTab]: tabCont.getId() });
        }
        const cloned = defaultModel.prototype.clone.apply(this, arguments);
        !fromParent && cloned.addAttributes({ [selectorTab]: '' });
        return cloned;
      }
    },
  });
}
