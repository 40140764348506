import DiscourseTopics from "../DiscourseTopics";
import {componentType} from "./index";

export const loadComponent = (editor) => {
  const componentDefinition = {
    extend: 'react-component',
    model: {
      toHTML(opts = {}) {
        let klass = this.get('type');
        const propsObject = this.getAttrToHTML();

        let innerComponents = this.components();
        let childHtml = '';
        if (innerComponents && innerComponents.length) {
          innerComponents.forEach((innerComponent) => {
            childHtml += innerComponent.toHTML();
          })
          let childSelectorClass = `${klass}-${this.ccid}-child`;
          childHtml = `<div class="${childSelectorClass} visually-hidden">${childHtml}</div>`;
          propsObject['childSelector'] = `.${childSelectorClass}`;
        }

        let props = JSON.stringify(propsObject);
        props = this.escapeHTML(props);

        return `${childHtml}<div data-react-class="${klass}" data-react-props="${props}" data-react-cache-id="${klass}-${this.ccid}"></div>`;
      },
      defaults: {
        components: [],
        component: DiscourseTopics,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        traits: [
          {
            type: 'text',
            name: 'title',
            label: 'Title'
          },
          {
            type: 'text',
            name: 'subtitle',
            label: 'Subtitle'
          },
          {
            type: 'text',
            name: 'cta_text',
            label: 'CTA text'
          },
          {
            type: 'text',
            name: 'cta_url',
            label: 'CTA URL'
          },
          {
            type: 'text',
            name: 'slug',
            label: 'Category slug'
          },
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === 'DISCOURSETOPICS';
    }
  };
  editor.Components.addType(componentType, componentDefinition);
}
