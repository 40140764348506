import * as React from "react";

const PreviousItemButton = ({onClick}) => {
  return (
    <button type='button' className='btn btn-prev' onClick={onClick} aria-label='Previous'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" fill="#5E5E5E"
              d="M10.0616 11.9999L15.5312 17.4696L14.4706 18.5303L7.94026 11.9999L14.4706 5.46961L15.5312 6.53027L10.0616 11.9999Z"/>
      </svg>
    </button>
  );
}

const NextItemButton = ({onClick}) => {
  return (
    <button type='button' className='btn btn-next' onClick={onClick} aria-label='Next'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" fill="#5E5E5E"
              d="M13.9384 12.0001L8.46875 6.53039L9.52941 5.46973L16.0597 12.0001L9.52941 18.5304L8.46875 17.4697L13.9384 12.0001Z"/>
      </svg>
    </button>
  );
}

/**
 * Header navigation component.
 *
 * @param {Object} props
 * @param {Function} props.onPreviousButtonClicked
 * @param {Function} props.onNextButtonClicked
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const HeaderNavigation = ({onPreviousButtonClicked, onNextButtonClicked}) => {
  return (
    <div className='btn-toolbar align-self-center' role='toolbar'>
      <div className='btn-group' role='group'>
        <PreviousItemButton onClick={onPreviousButtonClicked}/>
        <NextItemButton onClick={onNextButtonClicked}/>
      </div>
    </div>
  );
};

/**
 * Side navigation component.
 *
 * @param {Object} props
 * @param {Function} props.onPreviousButtonClicked
 * @param {Function} props.onNextButtonClicked
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const SideNavigation = ({onPreviousButtonClicked, onNextButtonClicked}) => {
  return (
    <React.Fragment>
      <div className='side-navigation side-navigation--prev'>
        <PreviousItemButton onClick={onPreviousButtonClicked}/>
      </div>
      <div className='side-navigation side-navigation--next'>
        <NextItemButton onClick={onNextButtonClicked}/>
      </div>
    </React.Fragment>
  );
}
