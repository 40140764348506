import PageNavigation from '../PageNavigation';
import {componentType} from './editor';

const componentDefinition = {
  extend: 'react-component',
  model: {
    init() {
      const titleTrait = this.getTrait('title');
      titleTrait.set('value', 'On this page')
    },
    defaults: {
      components: [],
      component: PageNavigation,
      stylable: false,
      resizable: false,
      editable: true,
      draggable: true,
      droppable: true,
      traits: [
        {
          type: 'text',
          name: 'title',
          label: 'Title'
        },
        {
          type: 'checkbox',
          name: 'use_numbering',
          label: 'Use numbering'
        },
        {
          type: 'description',
          text: '<p>Add the <code>toc-item</code> CSS class to auto-generate anchor links. Supported elements: h1, h2, h3, ul, and p.</p><p>To override the menu link text, use the <code>title</code> attribute.</p>',
        },
      ],
    },
  },
  isComponent: (el) => {
    return el.tagName === 'PAGENAVIGATION';
  }
};

const loadComponent = (editor, opts) => {
  editor.Components.addType(componentType, componentDefinition);
}

export default loadComponent;
