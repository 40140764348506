import GalleryWidget from '../GalleryWidget';

const loadComponent = (editor, opts) => {
  const componentDefinition = {
    extend: 'react-component',
    model: {
      toHTML(opts = {}) {
        let props = JSON.stringify(this.getAttrToHTML());
        let klass = this.get('type');

        const domElement = document.createElement('div');
        domElement.setAttribute('data-react-class', klass);
        domElement.setAttribute('data-react-props', props);
        domElement.setAttribute('data-react-cache-id', `${klass}-${this.ccid}`);

        const cssClasses = this.getClasses();
        if (cssClasses.length) {
          domElement.classList.add(...cssClasses);
        }

        return domElement.outerHTML;
      },

      defaults: {
        components: [],
        component: GalleryWidget,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        traits: [
          {
            type: 'text',
            name: 'id',
            label: 'ID',
            value: crypto.randomUUID(),
          },
          {
            name: 'id_description',
            type: 'description',
            text: '<p class="p--small fst-italic">Each widget must have a unique ID</strong></p>',
          },
          {
            type: 'text',
            name: 'header',
            label: 'Header'
          },
          {
            type: 'text',
            name: 'destination',
            label: 'Destination',
            placeholder: 'https://example.com'
          },
          {
            type: 'select',
            name: 'widget_type',
            label: 'Type',
            options: [
              {id: 'playlist', name: 'Playlist'},
            ],
            value: 'playlist'
          },
          {
            type: 'text',
            name: 'playlist_id',
            label: 'Playlist ID'
          },
          {
            type: 'checkbox',
            name: 'is_featured',
            label: 'Featured'
          },
          {
            type: 'checkbox',
            name: 'is_featured_playlist',
            label: 'Featured playlist'
          },
          {
            type: 'checkbox',
            name: 'open_session_in_new_tab',
            label: 'Open session in new tab'
          },
          {
            type: 'checkbox',
            name: 'hide_title',
            label: 'Hide title'
          },
        ],
      },
    },
    isComponent: (el) => el.tagName === opts.componentType.toUpperCase(),
  };

  editor.Components.addType(opts.componentType, componentDefinition);
}

export default loadComponent;
