import loadBlock from './block';
import loadComponent from './component';

/**
 * AddEvent calendar entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'AddEventCalendar',
    componentName: 'AddEvent Calendar',
    category: 'React Components',
    css: {
      root: ['nv-add-event-calendar'],
    },
    defaultProps: {
      copyable: false,
      removable: false,
      selectable: false,
      hoverable: false,
    }
  };
  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
};
