import * as React from 'react';
import {Code, List} from 'react-content-loader';

/**
 * Preloader.
 *
 * @param {string} title
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchLayoutLoader = ({title}) => {
  return (
    <div className="container faceted-filters">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="faceted-filters-form">
            {[...Array(5).keys()].map((e,k) => {
              return <Code key={k} speed={2} width={300} height={150} viewBox="0 0 300 150"/>
            })}
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12">
          {title && <h2 className="fsf-heading">{title}</h2>}
          <div className="faceted-filters-results">
            <List />
          </div>
        </div>
      </div>
    </div>
  );
};
