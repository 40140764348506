import loadBlock from './block';
import loadComponent from './component';

/**
 * Chart component entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'LegacyChart',
    componentName: 'Chart (legacy)',
    category: 'React Components',
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
}
