import ImageComparisonSlider from '../ImageComparisonSlider';

export default function loadComponent(editor, options) {
  const {componentCommandName} = options;
  const componentDefinition = {
    extend: 'react-component',
    model: {
      init() {
      },
      defaults: {
        components: [],
        component: ImageComparisonSlider,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        traits: [
          {
            label: 'Image 1',
            placeholder: 'https://example.com/1.jpg',
            type: 'text',
            name: 'image_1_url'
          },
          {
            label: 'Alt 1',
            placeholder: 'Alt text',
            type: 'text',
            name: 'image_1_alt'
          },
          {
            label: 'Image 2',
            placeholder: 'https://example.com/2.jpg',
            type: 'text',
            name: 'image_2_url'
          },
          {
            label: 'Alt 2',
            placeholder: 'Alt text',
            type: 'text',
            name: 'image_2_alt'
          },
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === options.componentType.toUpperCase();
    }
  };
  editor.Components.addType(options.componentType, componentDefinition);
}
