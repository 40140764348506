import * as React from 'react';
import {useContext} from 'react';
import {StateContext} from '../FeedAggregator';
import {List} from 'react-content-loader';

const splitArrayToChunks = (arr, chunkSize) => {
  const chunks = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
};

const CardPreloader = (props) => {
  const {ctaText} = props;
  let cta = ctaText || 'Read more';

  return (
    <div className='card'>
      <div className='card-image'></div>
      <div className='card-body'>
        <h5 className='card-title'>
          <List/>
        </h5>
        <p className='card-text'>
          <List/>
        </p>
      </div>
    </div>
  )
};

const Image = (props) => {
  let {src, title} = props;

  if (!src) {
    return (
      <div class='card-image'></div>
    );
  }

  return (
    <img src={src} alt={title} title={title} loading='lazy'/>
  );
}

const Card = (props) => {
  const {title, image, excerpt, link, date, ctaText} = props;
  const {showDate, showExcerpt} = props;
  let cta = ctaText || 'Read more';

  let linkTarget;
  try {
    linkTarget = new URL(link).hostname === location.hostname ? '_self' : '_blank';
  } catch (e) {
    linkTarget = '_self';
  }

  return (
    <div className='card'>
      <Image src={image} title={title} />
      <div className='card__content'>
        <div className='card__data'>
          {showDate && <div className='card__date p--small mt-3 mb-3'>{date}</div>}
          <h5 className='card__title h--smallest mb-0'>{title}</h5>
          <div className="separator separator--30"></div>
          {showExcerpt && <div className='card__text' dangerouslySetInnerHTML={{__html: excerpt}}></div>}
          {showExcerpt && excerpt && excerpt.length && <div className="separator separator--30"></div>}
        </div>
        <div className='card__actions'>
          <a href={link} target={linkTarget} className='link-cta'>{cta}</a>
        </div>
      </div>
    </div>
  )
};

const LayoutContent = ({columnClassName, componentState, chunkSize}) => {
  const {itemsCount, isDataLoaded, cards, ctaText, showDate, showExcerpt, error} = componentState;
  let items = splitArrayToChunks(cards, chunkSize);

  if (error) {
    return <Error/>;
  }

  if (!isDataLoaded || items.length === 0) {
    return <>
      {splitArrayToChunks([...Array(parseInt(itemsCount))], chunkSize).map((chunk, idx) => {
        return <div key={idx} className='row'>
          {chunk.map((cardData, cardIdx) => <div key={cardIdx} className={columnClassName}>
            <CardPreloader ctaText={ctaText}/>
          </div>)}
        </div>;
      })}
    </>
  }

  return (
    <>
      {items.map((chunk, idx) => {
        return <div key={idx} className='row'>
          {chunk.map((cardData, cardIdx) => <div key={cardIdx} className={columnClassName}>
            <Card {...cardData} ctaText={ctaText} showExcerpt={showExcerpt} showDate={showDate}/>
          </div>)}
        </div>;
      })}
    </>
  );
};

export const Error = () => {
  return (
    <div></div>
  );
}

export const ThreeColumnsLayout = () => {
  const [componentState] = useContext(StateContext);

  return <LayoutContent columnClassName='col-lg-4 col-md-4 col-sm-12 col-12' componentState={componentState} chunkSize={3}/>;
};

export const FourColumnsLayout = () => {
  const [componentState] = useContext(StateContext);

  return <LayoutContent columnClassName='col-lg-3 col-md-6 col-sm-6 col-12' componentState={componentState} chunkSize={4}/>;
};
