import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Hits, InstantSearch } from 'react-instantsearch-dom';
import {
  FieldsMappingContext,
  QueryContext,
  ViewOptionsContext,
} from './Context';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { layouts, mappingTypes } from '../data-mapping';
import {CardSearchResult} from './search-results/CardSearchResult';
import {AccordionSearchResult} from './search-results/AccordionSearchResult';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'qs';
import { FacetedSearchLayoutStandard } from './FacetedSearchLayoutStandard';
import { FacetedSearchLayoutCompact } from './FacetedSearchLayoutCompact';

const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = searchState => searchState ? createURL(searchState) : '';
const urlToSearchState = location => qs.parse(location.search.slice(1));

/**
 * Builds search client adapter.
 *
 * @param {String} protocol
 * @param {String} host
 * @param {String} port
 * @param {String} apiKey
 * @param {Object} collection
 * @param {String} collection.name
 * @param {String} queryBy
 * @param {String} filterBy
 * @returns {TypesenseInstantSearchAdapter}
 */
function buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy) {
  const {name} = collection;
  // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  const cacheTime = 2 * 60; // TODO Move to collection config?
  const config = {
    server: {
      apiKey: apiKey,
      nodes: [
        {
          host: host,
          port: port,
          path: '',
          protocol: protocol,
        },
      ],
      cacheSearchResultsForSeconds: cacheTime,
    },
    collectionSpecificSearchParameters: {},
  };

  config.collectionSpecificSearchParameters[name] = {
    query_by: 'title'
  };

  if (filterBy) {
    config.additionalSearchParameters = {
      filter_by: filterBy,
    }
  }

  if (queryBy && queryBy.trim().length > 0) {
    config.collectionSpecificSearchParameters[name]['query_by'] = queryBy;
  }

  return new TypesenseInstantSearchAdapter(config);
}

/**
 * Search results factory.
 *
 * @param {Object} props
 * @param {string} props.viewMode
 * @param {Object} props.fieldsMapping
 * @param {Object} props.fieldsMapping.card
 * @param {Object} props.fieldsMapping.accordion
 * @returns {JSX.Element}
 * @constructor
 */
const SearchResults = (props) => {
  const {viewMode, fieldsMapping} = props;
  const {card, accordion} = useContext(FieldsMappingContext);
  const [, setCardData] = card;
  const [, setAccordionData] = accordion;

  useEffect(() => {
    setCardData(fieldsMapping.card);
    setAccordionData(fieldsMapping.accordion);
  }, []);

  switch (viewMode) {
    case mappingTypes.card:
      return <Hits hitComponent={CardSearchResult}/>;
    case mappingTypes.accordion:
      return <Hits hitComponent={AccordionSearchResult}/>;
    default:
      return <>View mode is not supported.</>;
  }
};

/**
 * Faceted search main component.
 *
 * @param {Object} props
 * @param {Object} props.collection
 * @param {string} props.collection.name
 * @param {Array}  props.collection.filters
 * @param {Object} props.collection.field_mappings
 * @param {Object} props.collection.field_mappings.card
 * @param {Object} props.collection.field_mappings.accordion
 * @param {string} props.viewMode
 * @param {string} props.title
 * @param {string} props.host
 * @param {string} props.protocol
 * @param {string} props.apiKey
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchLayout = (props) => {
  const {collection, viewMode, viewOptions, filterBy = '', layout} = props;
  const {protocol,  host, port, apiKey, queryBy} = props;
  const {filters, field_mappings, sort_by: sortBy} = collection;
  const {card: cardViewOptionsCtx} = useContext(ViewOptionsContext);
  const {filterBy: filterByCtx} = useContext(QueryContext);
  const [adapter, setAdapter] = useState({});

  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const isStateChanged = (a, b) => JSON.stringify(a) !== JSON.stringify(b);
  const quickFiltersRef = useRef();

  function onSearchStateChange(nextSearchState) {
    if (isStateChanged(searchState, nextSearchState)) {
      history.push(searchStateToUrl(nextSearchState), nextSearchState);
      setSearchState(nextSearchState);
    }
  }

  // Styling.
  const cssClasses = ['faceted-filters-results'];
  const cssClassesHeading = ['faceted-search-heading'];
  const cssClassesStats = ['faceted-search-stats-wrapper'];
  const cssClassesFacetedSearchPills = ['faceted-search-pills'];
  switch (viewMode) {
    case mappingTypes.card:
      cssClasses.push('faceted-filters-results--cards');
      cssClassesHeading.push('fsf-heading--cards');
      cssClassesFacetedSearchPills.push('faceted-search-pills--cards');
      cssClassesStats.push('faceted-search-stats-wrapper--cards');
      break;
    case mappingTypes.accordion:
      cssClasses.push('accordion');
      cssClassesHeading.push('fsf-heading--accordion');
      cssClassesFacetedSearchPills.push('faceted-search-pills--accordion');
      break;
  }

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);
    if (isStateChanged(searchState, nextSearchState)) {
      setSearchState({...nextSearchState});
    }
  }, [location]);

  useEffect(() => {
    setAdapter(buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy));
  }, [protocol, host, port, apiKey, collection, queryBy, filterBy, layout]);

  useEffect(() => {
    // Set initial view options for card.
    const [, setCardViewOptions] = cardViewOptionsCtx;
    setCardViewOptions(viewOptions);

    // Set filter by context.
    const [, setFilterBy] = filterByCtx;
    setFilterBy(filterBy);
  }, [viewOptions]);

  let layoutComponent = <></>;
  if (layout === layouts.compact) {
    layoutComponent = <FacetedSearchLayoutCompact
      sortBy={sortBy}
      quickFiltersRef={quickFiltersRef}
      cssClasses={cssClasses}
      cssClassesHeading={cssClassesHeading}
      cssClassesStats={cssClassesStats}
      SearchResults={SearchResults}
      fieldsMapping={field_mappings}
      filters={filters}
      {...props}
    />
  } else {
    layoutComponent = <FacetedSearchLayoutStandard
      sortBy={sortBy}
      quickFiltersRef={quickFiltersRef}
      cssClasses={cssClasses}
      cssClassesHeading={cssClassesHeading}
      cssClassesStats={cssClassesStats}
      cssClassesFacetedSearchPills={cssClassesFacetedSearchPills}
      SearchResults={SearchResults}
      fieldsMapping={field_mappings}
      filters={filters}
      {...props}
    />;
  }

  if (!adapter.searchClient) {
    return null;
  }

  return (
    <InstantSearch
      indexName={collection.name}
      searchClient={adapter.searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      {layoutComponent}
    </InstantSearch>
  );
}
