import { componentType } from './index.js';
import {componentLabel} from '../utils/editor';
import icon from './icon.svg';

export function loadBlock(editor, opts = {}) {
  editor.BlockManager.add(componentType, {
    label: componentLabel('Discourse topics', icon),
    category: 'React Components',
    activate: true,
    select: true,
    content: '<DiscourseTopics></DiscourseTopics>'
  });
}
