import {sanitizeEditorOption} from '../utils/editor';

const updateTrait = (elInput, component, trait) => {
  const componentName = trait.get('name')
  const storage = elInput.querySelector('.js-options-set-storage');
  const attribute = {};
  attribute[componentName] = JSON.stringify(storage.value);
  component.addAttributes(attribute);
  elInput.dispatchEvent(new Event('change'));
}

export const TraitTypeOptionsSet = 'trait-options-set';

export const AddOptionsSetTrait = (editor) => {
  const traitConfig = {
    noLabel: true,
    createInput({trait}) {
      const traitOpts = trait.get('options') || [];
      const label = trait.get('label') || '';

      const el = document.createElement('div');
      el.innerHTML = `
      <div class="options-set__items">
        <div class="gjs-trt-trait ">
          <div class="gjs-label gjs-bold" title="${label}">${label}</div>
        </div>        
        ${traitOpts.map(opt =>
        `<div class="gjs-trt-trait gjs-trt-trait--checkbox">
          <div class="gjs-field-wrp">
            <div class="gjs-label" title="${opt.label}">${opt.label}</div>
          </div>
          <div class="gjs-field-wrp--checkbox" data-input="">
            <label class="gjs-field gjs-field-checkbox" data-input="">
              <input type="checkbox" name="${opt.id}" value="1" class="js-options-set-checkbox">
              <i class="gjs-chk-icon"></i>
            </label>
          </div>
        </div>`)
        .join('')}
      </div>
      <div class="options-set-storage-wrapper">
        <textarea class="js-options-set-storage gjs-visually-hidden" placeholder="${label}"></textarea>
      </div>`;

      const storage = el.querySelector('.js-options-set-storage');
      const checkboxes = el.querySelectorAll('.js-options-set-checkbox');
      checkboxes.forEach((checkbox, idx) => {
        checkbox.addEventListener('change', (event) => {
          const state = [...checkboxes].map((el) => `${el.name}:${el.checked ? 1 : 0}`);
          storage.value = state.join(';');
        })
      });
      return el;
    },
    // Update the component based element changes (click on checkboxes).
    onEvent({elInput, component, trait}) {
      updateTrait(elInput, component, trait);
    },
    // Triggers first.
    onUpdate({elInput, component, trait}) {
      const attributeName = trait.get('name') || ''
      const attributes = component.getAttributes();

      const storage = elInput.querySelector('.js-options-set-storage');
      let defaultStorageValue = "";
      if (attributes && attributes[attributeName]) {
        defaultStorageValue = attributes[attributeName];
        defaultStorageValue = sanitizeEditorOption(defaultStorageValue);
      }

      if (defaultStorageValue.length) {
        let optionsParts = defaultStorageValue.split(';');
        optionsParts.forEach((option) => {
          const [key, value] = option.split(':');
          const checkboxElement = elInput.querySelector(`input[type="checkbox"][name="${key}"]`)
          if (checkboxElement) {
            checkboxElement.checked = !!parseInt(value);
          }
        });
      }

      storage.value = defaultStorageValue;
      component.trigger('options-set-update', attributes)
    },
  };

  editor.TraitManager.addType(TraitTypeOptionsSet, traitConfig);
};
