import React, {useEffect, useRef, useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {AutocompleteModel} from '../autocomplete';
import {addError} from '../../../packs/tracing';

/**
 * Returns a request URL for the given model.
 *
 * https://github.com/codevise/activeadmin-searchable_select#fetching-options-via-ajax
 *
 * @param {String} type
 * @param {String} query
 *
 * @returns {string}
 */
const allOptionsUrl = (type, query = '') => {
  let route;
  switch (type) {
    case AutocompleteModel.form:
      route = '/nv/admin/forms/all_options.json';
      break;
    case AutocompleteModel.program:
      route = '/nv/admin/programs/all_options.json';
      break;
    default:
      throw new Error(`Failed to build 'all options' URL. Unsupported type: ${type}`);
  }
  if (query.length) {
    route = `${route}?term=${query}`
  }
  return route;
}

const singleEntityUrl = (id, type) => {
  switch (type) {
    case AutocompleteModel.form:
      return `/nv/admin/forms/${id}.json`;
    case AutocompleteModel.program:
      return `/nv/admin/programs/${id}.json`;
    default:
      throw new Error(`Failed to build 'single entity' URL. Unsupported type: ${type}`);
  }
}

const singleEntityToOptions = (data, type) => {
  switch (type) {
    case AutocompleteModel.form:
    case AutocompleteModel.program:
      return [{id: data.id, text: data.name}];
    default:
      throw new Error(`Unable to convert entity to an options list. Unsupported type: ${type}`);
  }
}

/**
 * React component for label.
 *
 * @param {object} props
 * @param {Array} props.items
 * @param {String} props.entityType
 * @param {Boolean} props.required
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SelectedItem = ({items, entityType, required}) => {
  const fallbackComponent = <div className="gjs-react-autocomplete__label">
    <span className="gjs-react-autocomplete__model-name">{entityType}:{required && <sub>*</sub>}</span> None selected.
  </div>;

  if (!Array.isArray(items) || !items[0]) {
    return fallbackComponent;
  }
  const selected = items[0];
  if (!selected.hasOwnProperty('text') || !selected.hasOwnProperty('id')) {
    return fallbackComponent;
  }
  return <div className="gjs-react-autocomplete__label">
    <span className="gjs-react-autocomplete__model-name">
      {entityType}:{required && <sub>*</sub>}
    </span>
    <span>{selected.text} (ID: {selected.id})</span>
  </div>
};

/**
 * Error handler.
 *
 * @param {Error} error
 */
const handleFailedRequest = (error) => {
  addError('autocomplete', error);
};

/**
 * Autocomplete widget.
 *
 * @param {Object} props
 * @param {String} props.model
 * @param {String} props.placeholder
 * @param {Function} props.onChangeHandler
 * @param {Number|String} props.entityId
 * @param {Number|String} props.entityName
 * @param {boolean} props.required
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const AutocompleteWidget = ({model, placeholder, onChangeHandler, entityId, entityName, required}) => {
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const ref = useRef();
  const localOnChangeHandler = (val) => {
    onChangeHandler(val);
    setSingleSelections(val);
  };

  // Fetch initial data, populate options list and selected item.
  useEffect(() => {
    fetch(allOptionsUrl(model, entityName))
      .then(response => response.json())
      .then(data => {
        const {results} = data;
        const list = options;
        const selected = [];
        results.forEach((item) => {
          if (parseInt(entityId) === parseInt(item.id)) {
            selected.push(item);
          }
          list.push(item);
        });
        if (entityId && selected.length === 0) {
          fetch(singleEntityUrl(entityId, model))
            .then(response => response.json())
            .then(data => {
              const singleEntityOptions = singleEntityToOptions(data, model);
              setOptions(singleEntityOptions);
              setSingleSelections(singleEntityOptions);
              localOnChangeHandler(singleEntityOptions);
              setLoaded(true);
            })
            .catch(e => handleFailedRequest(e));
          return;
        }
        setOptions(list);
        setSingleSelections(selected);
        setLoaded(true);
      })
      .catch(e => handleFailedRequest(e));
  }, []);

  const handleSearch = (query) => {
    setIsLoading(true);
    fetch(allOptionsUrl(model, query))
      .then((resp) => resp.json())
      .then(({ results }) => {
        setOptions(results);
        setIsLoading(false);
      });
  };

  if (!loaded) {
    return <p className="gjs-autocomplete-widget__preloader">
      <i className="fa fa-circle-o-notch fa-spin fa-fw"></i> Fetching values...
    </p>
  }

  return <>
    <SelectedItem items={singleSelections} entityType={model} required={required}/>
    <div className="gjs-react-autocomplete__controls">
      <div className="gjs-react-autocomplete__field">
        <AsyncTypeahead
          id={`typeahead-single-${model}`}
          labelKey="text"
          onSearch={handleSearch}
          onChange={localOnChangeHandler}
          options={options}
          placeholder={placeholder}
          selected={singleSelections}
          ref={ref}
          isLoading={isLoading}/>
      </div>
      <div className="gjs-react-autocomplete__btn">
        {required === false && singleSelections?.length > 0 && <button className="gjs-react-autocomplete__reset" onClick={() => {
          localOnChangeHandler([]);
          ref.current?.clear();
        }}><i className="fa fa-times" aria-hidden="true"></i></button>}
      </div>
    </div>
  </>
}
