const Commands = {
  copy: 'nv:copy',
  paste: 'core:paste'
};

const clipboardStorage = {
  clipboardKey: 'gjs.clipboard',
  setItem(value) {
    localStorage.setItem(this.clipboardKey, JSON.stringify(value));
  },
  getItem() {
    const value = localStorage.getItem(this.clipboardKey);
    return JSON.parse(value);
  }
};

const addButtonToComponentToolbar = (component, icon, command, targetId) => {
  const {attributes} = component;
  const {copyable} = attributes;
  if (!copyable) {
    console.warn('Component cannot be copied');
    return;
  }
  const defaultToolbar = component.get('toolbar');
  const commandExists = defaultToolbar.some(item => item.command === command);
  if (commandExists) {
    console.log('Button already exists');
    return;
  }

  const tmpToolbar = [];
  [...defaultToolbar].map(item => {
    if (targetId === item.command) {
      tmpToolbar.push({attributes: {class: icon}, command: command});
    }
    tmpToolbar.push(item);
  });

  component.set({toolbar: tmpToolbar});
};

const addButtons = (editor) => {
  editor.on('component:selected', () => {
    const component = editor.getSelected();
    addButtonToComponentToolbar(component, 'fa fa-files-o', Commands.copy, 'tlb-delete');
    addButtonToComponentToolbar(component, 'fa fa-clipboard', Commands.paste, 'tlb-delete');
  });
};

const addKeymaps = (editor) => {
  const Keymaps = {
    copy: {
      id: Commands.copy,
      keys: '⌘+c, ctrl+c',
      handler: Commands.copy
    },
    paste: {
      id: Commands.paste,
      keys: '⌘+v, ctrl+v',
      handler: Commands.paste
    },
  };

  for (const [, config] of Object.entries(Keymaps)) {
    const {id, keys, handler} = config;
    editor.Keymaps.add(id, keys, handler);
  }
};

const registerCommands = (editor) => {
  const commands = editor.Commands;

  commands.add(Commands.copy, (editor) => {
    const selected = [...editor.getSelectedAll()];
    let filteredSelected = selected.filter(item => item.attributes.copyable === true);
    if (filteredSelected.length) {
      clipboardStorage.setItem(filteredSelected);
    }
  });

  commands.add(Commands.paste, editor => {
    const selected = editor.getSelected();
    const components = clipboardStorage.getItem();
    if (selected && Array.isArray(components)) {
      editor.getSelected().parent().append(components.pop(), {at: selected.index() + 1});
    } else {
      editor.addComponents(components);
    }
  });
}

export default (editor) => {
  registerCommands(editor);
  addButtons(editor);
  addKeymaps(editor);
};
