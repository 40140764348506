/**
 * Command trait.
 *
 * Allows to run defined command for specific component.
 *
 * @param editor
 * @returns {HTMLButtonElement}
 * @constructor
 */
const AddRunCommandTrait = (editor) => {
  editor.TraitManager.addType('run-command', {
    eventCapture: ['input'],
    noLabel: true,
    createInput({trait}) {
      const commandName = trait.get('commandName');
      const buttonLabel = trait.get('buttonLabel');

      const buttonElement = document.createElement('button');
      buttonElement.innerHTML = buttonLabel || 'Run command'
      buttonElement.className = 'mt-2 mb-2 ms-2';
      buttonElement.onclick = () => {
        editor.runCommand(commandName);
      };

      return buttonElement;
    }
  })
};

export default AddRunCommandTrait;
