import * as React from 'react';
import {ReactSurveyElement} from 'survey-react-ui';

export class ProgramApplicationFormProgressBar extends ReactSurveyElement {
  render() {
    const {props} = this;
    const {model} = props.item.innerItem.data;

    let str = `Step ${model.activePage.visibleIndex + 1} of ${model.visiblePageCount}`;
    return (<div className="sv-progressbar-steps">{str}</div>);
  }

  componentDidUpdate(prevProps, prevState) {
    const {model} = prevProps.item.innerItem.data;

    let buttonsBar = document.querySelector('.sd-action-bar.sd-footer');
    if (buttonsBar) {
      buttonsBar.setAttribute('data-page', model.activePage.visibleIndex);
    }
  }
}
