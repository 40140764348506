import { componentLabel } from '../utils/editor';
import icon from './icons.svg';

/**
 * Registers the component to the block manager.
 *
 * @param {Object} editor
 * @param {String} componentType
 * @param {String} category
 */
export default (editor, { componentType, category }) => {
  editor.BlockManager.add(componentType, {
    label: componentLabel('Icon', icon),
    category: category,
    content: {
      type: componentType
    },
  });
}
