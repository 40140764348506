import newspaperIcon from './icons/newspaper.svg';

/**
 * GrapesJS block definition.
 */
export default function loadBlock(editor, options = {}) {
  editor.BlockManager.add(options.componentType, {
    label: `<div class='gjs-fa-icon'><img src="${newspaperIcon}" alt="Feed aggregator"/></div><div>Feed aggregator</div>`,
    category: 'React Components',
    activate: true,
    select: true,
    content: '<FeedAggregator />'
  });
}
