import { imagePlaceholderUrl } from '../utils/editor';

const imageColumnComponents = [
  {
    tagName: 'figure',
    components: [
      {
        type: 'image',
        src: imagePlaceholderUrl(),
        classes: ['card-img-top']
      },
      {
        tagName: 'figcaption',
        components: [
          { type: 'text', content: 'Figure caption' }
        ],
      },
    ]
  },
];

const textColumnComponents = [
  {
    type: 'header',
    content: 'How to Deploy NVIDIA Riva Speech and Translation AI in the Public Cloud',
    tagName: 'h4',
    classes: ['h--small']
  },
  {
    type: 'paragraph',
    content: 'Conversational Al is the use of machine \n' +
      'learning to develop speech-based apps that allow humans to interact naturally with ' +
      'devices, machines, and computers using audio.',
  },
  {
    type: 'paragraph',
    content: 'Conversational Al is the use of machine \n' +
      'learning to develop speech-based apps that allow humans to interact naturally with ' +
      'devices, machines, and computers using audio.',
    classes: ['mb-0']
  },
];

const traits = [
  {
    type: 'select',
    label: 'Layout',
    name: 'layout',
    changeProp: 1,
    options: [
      {value: 'image-text', name: 'Image | Text'},
      {value: 'text-image', name: 'Text | Image'},
    ],
  },
];

/**
 * Waterfall component.
 *
 * @param {Object} editor
 * @param {Object} config
 */
export default (editor, config) => {
  const components = [
    {
      type: 'column',
      classes: config.css.imageColumn,
      components: imageColumnComponents
    },
    {
      type: 'column',
      classes: config.css.textColumn,
      components: textColumnComponents
    }
  ];

  const componentDefinition = {
    model: {
      defaults: {
        ...config.defaultProps,
        name: config.componentName,
        traits: traits,
        classes: config.css.root,
        components: components,
      },
      init() {
        this.listenTo(this, 'change:layout', this.__onChangeLayout);
      },
      __onChangeLayout(selected, value, _opts) {
        const textColumn = this.find('.js-text-column');
        const imageColumn = this.find('.js-image-column');
        switch (value) {
          case  "image-text":
            this.components([...imageColumn, ...textColumn]);
            break;
          case "text-image":
            this.components([...textColumn, ...imageColumn]);
            break;
        }
      }
    },
  };

  const {DomComponents} = editor;
  DomComponents.addType(config.componentType, componentDefinition);
};
