import { imagePlaceholderUrl } from '../utils/editor';

/**
 * Builds list links.
 *
 * @param {String} text
 *   Link text.
 * @param {Number} count
 *   Links count.
 * @returns {[]}
 */
function listLinks(text, count) {
  const links = [];
  for (let i = 0; i < count; i++) {
    links.push(
      {
        type: 'list-item',
        components: [
          {
            type: 'link',
            attributes: {
              href: 'https://developer.nvidia.com',
            },
            content: text
          }
        ],
      });
  }
  return links;
}

/**
 * Builds sidebar block with given content.
 *
 * @param {Array} components
 *   Components list.
 * @param {Array} classes
 *   Additional CSS classes.
 * @returns {{components, classes: string[], name: string, tagName: string}}
 */
function sidebarBlock(components, classes = []) {
  return {
    tagName: 'div',
    name: 'Sidebar Block',
    classes: ['sidebar-block', ...classes],
    components: components,
  }
}

export function mainAreaContent() {
  const defaultContent = `<a href="https://developer.nvidia.com">Conversational Al</a> is the use of machine 
learning to develop speech-based apps that allow humans to interact naturally with devices, machines, and computers using audio.`;

  const breadcrumbComponents = [
    {
      tagName: 'li', editable: true, classes: ['breadcrumb-item'], components: [
        {type: 'link', content: 'Home', attributes: {href: 'https://developer.nvidia.com'}}
      ]
    },
    {
      tagName: 'li', editable: true, classes: ['breadcrumb-item'], components: [
        {type: 'link', content: 'Page', attributes: {href: 'https://developer.nvidia.com'}}
      ]
    },
    {
      tagName: 'li', editable: true, classes: ['breadcrumb-item'], components: [
        {type: 'text', content: 'Current Page'}
      ]
    },
  ];

  return [
    {
      tagName: 'ol',
      classes: ['breadcrumb'],
      components: breadcrumbComponents,
    },
    {
      type: 'header',
      content: 'Lorem ipsum',
      tagName: 'h1',
      classes: ['h--large']
    },
    {
      type: 'paragraph',
      content: defaultContent,
      classes: ['p--large' ,'mb-0']
    },
  ];
}

/**
 * "Quick links" sidebar content.
 *
 * @returns []
 */
export function quickLinks() {
  return [
    sidebarBlock([
      {
        type: 'header',
        content: 'Catalog:',
        tagName: 'p',
        classes: ['h--smallest', 'mb-3']
      },
      {
        tagName: 'div',
        classes: ['btn-group'],
        components: [
          {
            type: 'link',
            content: 'API Catalog',
            classes: ['btn', 'btn-cta']
          },
          {
            type: 'link',
            content: 'API Catalog',
            classes: ['btn', 'btn-cta', 'btn-cta--dark']
          },
        ],
      }
    ]),
    sidebarBlock([
      {
        type: 'header',
        content: 'Quick Links:',
        tagName: 'p',
        classes: ['h--smallest', 'mb-3']
      },
      {
        tagName: 'div',
        classes: ['quick-links'],
        components: [
          {
            type: 'list',
            classes: ['nv-list'],
            components: listLinks('Demo Link', 3)
          },
          {
            type: 'list',
            classes: ['nv-list'],
            components: listLinks('Demo Link', 3)
          },
        ],
      }
    ]),
  ];
}

/**
 * "Key Topics" sidebar content.
 *
 * @returns []
 */
export function keyTopics() {
  return [
    sidebarBlock([
      {
        type: 'header',
        content: 'Scroll to Resources:',
        tagName: 'p',
        classes: ['h--smallest', 'mb-3'],
      },
      {
        type: 'link',
        classes: ['btn', 'btn-cta'],
        attributes: {
          href: 'https://developer.nvidia.com',
        },
        content: 'Learning Library'
      },
    ]),
    sidebarBlock([
      {
        type: 'header',
        content: 'Key Topics:',
        tagName: 'p',
        classes: ['h--smallest', 'mb-3'],
      },
      {
        tagName: 'ul',
        classes: ['list-group'],
        components: [
          {type: 'list-item', components: [{type: 'text', content: 'Key Topic 1'}]},
          {type: 'list-item', components: [{type: 'text', content: 'Key Topic 2'}]},
          {type: 'list-item', components: [{type: 'text', content: 'Key Topic 3'}]},
        ]
      }
    ], ['key-topics']),
  ]
}

/**
 * "CTAs Only" sidebar content.
 *
 * @returns []
 */
export function ctasOnly() {
  return [
    sidebarBlock([
      {
        type: 'header',
        content: 'Catalog:',
        tagName: 'p',
        classes: ['h--smallest']
      },
      {
        tagName: 'div',
        classes: ['btn-group'],
        components: [
          {
            type: 'link',
            content: 'API Catalog',
            attributes: {
              href: 'https://developer.nvidia.com',
            },
            classes: ['btn', 'btn-cta']
          },
        ],
      },
    ]),
    sidebarBlock([
      {
        type: 'header',
        content: 'Register for GTC:',
        tagName: 'p',
        classes: ['h--smallest']
      },
      {
        tagName: 'div',
        classes: ['btn-group'],
        components: [
          {
            type: 'link',
            components: [
              {tagName: 'div', content: 'Watch Keynote'},
              {type: 'nv-icon', iconName: 'play', attributes: {'data-icon': 'play', iconfamily: 'fa'}}
            ],
            attributes: {
              href: 'https://developer.nvidia.com',
            },
            classes: ['btn', 'btn-cta', 'btn-cta--dark']
          },
        ],
      },
    ]),
  ];
}

/**
 * "Media Card" sidebar content.
 *
 * @returns []
 */
export function mediaCard() {
  return [
    sidebarBlock([
      {
        tagName: 'div',
        classes: ['card', 'media-card'],
        components: [
          {type: 'image', src: imagePlaceholderUrl(), classes: ['card-img-top']},
          {
            type: 'link',
            classes: ['link-cta'],
            attributes: {
              href: 'https://developer.nvidia.com',
            },
            content: 'Watch GTC Keynote',
          }
        ],
      }])
  ];
}

/**
 * "Text Card" sidebar content.
 *
 * @returns []
 */
export function textCard() {
  return [
    sidebarBlock([
      {
        tagName: 'div',
        classes: ['card', 'banner-text-card'],
        components: [
          {
            type: 'text',
            classes: ['card__pretitle'],
            content: 'Featured'
          },
          {
            type: 'header',
            content: 'How to Deploy NVIDIA Riva Speech and Translation AI in the Public Cloud',
            tagName: 'h4',
            classes: ['h--smaller']
          },
          {
            type: 'link',
            classes: ['link-cta'],
            attributes: {
              href: 'https://developer.nvidia.com',
            },
            content: 'Learn More',
          }
        ],
      }])
  ];
}
