import * as React from 'react';
import DOMPurify from 'dompurify';

/**
 *
 * @param {HTMLElement|Boolean} descriptionHTML
 * @param {String} status
 *   Application status.
 * @returns {React.JSX.Element|*}
 */
function statusDescription(descriptionHTML, status) {
  if (descriptionHTML) {
    return (
      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(descriptionHTML)}} />
    );
  }
  return I18n.t(`alerts.program_applications.${status}.description`);
}

/**
 * Application CTA component.
 *
 * @param {String} status
 *   Application status.
 * @param {Number} programId
 * @param {String} memberPageURL
 * @param {String} infoPageURL
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
function ApplicationCTA({status, programId, memberPageURL, infoPageURL}) {
  if (status === 'needs_more_info' && ![876061, 879192, 905928].includes(programId)) {
    return (
      <a className='link-edit text-transform-unset' href={`/programs/${programId}/application/edit`}>
        {I18n.t(`alerts.program_applications.${status}.cta`)}
      </a>
    );
  }
  if (status === 'approved' && memberPageURL) {
    return (
      <a className='link-cta text-transform-unset' href={memberPageURL}>
        {I18n.t(`alerts.program_applications.${status}.cta`)}
      </a>
    );
  }

  return <a className='link-cta text-transform-unset' href={infoPageURL.toString()}>{I18n.t('alerts.program_applications.back')}</a>;
}

/**
 * Program application status dialog component.
 *
 * @param {String|Boolean} status
 *   Program application status.
 * @param {String|Boolean} description
 * @param {Number} programId
 * @param {String|Boolean} memberPageURL
 * @param {String|Boolean} infoPageURL
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const ProgramApplicationStatusDialog = ({status, description, programId, memberPageURL, infoPageURL}) => {
  return(
    <div id='application-status' className='card'>
      <div className='card-body'>
        <div className='mb-2'>
            <span className={`badge bg-${status}`}>
              {I18n.t(`alerts.program_applications.${status}.label`)}
            </span>
        </div>
        <div id='app-status'>
          <div className='card-text mb-0'>
            <h4 className='card-title mb-2'>
              {I18n.t(`alerts.program_applications.application_status`)}
            </h4>
            {statusDescription(description, status)}
          </div>
          <div className='app-link'>
            <ApplicationCTA status={status} programId={programId} memberPageURL={memberPageURL} infoPageURL={infoPageURL} />
          </div>
        </div>
      </div>
    </div>
  );
}
