import {componentCommandName, componentKey} from './editor';
import {removeTags} from '../utils/dom';

export function loadCommands(editor, opts) {
  const {Commands} = editor;
  const {modalTitle, codeViewOptions, modalButtonLabel} = opts;

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML('beforeend', content);
    }
  }

  Commands.add(componentCommandName, {
    componentKey,
    run(editor, sender, opts = {}) {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const {target} = this;

      if (target && target.get('editable')) {
        this.showCustomCode(target);
      }
    },
    stop() {
      editor.Modal.close();
    },
    showCustomCode(target) {
      const {editor, options} = this;
      const title = options.title || modalTitle;
      const content = this.getContent();
      const code = target.get(componentKey) || '';

      editor.Modal
        .open({title, content})
        .getModel()
        .once('change:open', () => editor.stopCommand(this.id));

      this.getCodeViewer().setContent(code);
    },

    /**
     * Get all the content for the custom code.
     *
     * @return {HTMLElement}
     */
    getContent() {
      const {editor} = this;
      const content = document.createElement('div');
      const codeViewer = this.getCodeViewer();
      const pfx = editor.getConfig('stylePrefix');

      content.className = `${pfx}custom-code`;
      content.appendChild(codeViewer.getElement());

      appendToContent(content, this.getContentActions());
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    /**
     * Return the code viewer instance.
     *
     * @return {CodeViewer}
     */
    getCodeViewer() {
      const {editor} = this;

      if (this.codeViewer) {
        return this.codeViewer;
      }

      this.codeViewer = editor.CodeManager.createViewer({
        codeName: 'htmlmixed',
        theme: 'hopscotch',
        readOnly: 0,
        ...codeViewOptions,
      });

      return this.codeViewer;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement.
     *
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const {editor} = this;
      const btn = document.createElement('button');
      const pfx = editor.getConfig('stylePrefix');

      btn.innerHTML = modalButtonLabel;
      btn.style.marginTop = '0.5rem';
      btn.className = `${pfx}btn-prim ${pfx}btn-import__markup`;
      btn.onclick = () => this.handleSave();

      return btn;
    },

    /**
     * Handle the main save task.
     */
    handleSave() {
      const {editor, target} = this;
      let code = this.getCodeViewer().getContent();

      code = removeTags(code, ['script'], 'custom-html')
      target.set(componentKey, code);
      editor.Modal.close();
    },
  })
}
