export const slugify = str =>
  str
    .toLowerCase()
    .trim()
    // Replace non-word and non-Chinese characters with hypens
    .replace(/[^\w|\u4e00-\u9fff|\u3400-\u4dbf]/g, '-')
    // Replace repeating hypens and underscores with a single hyphen
    .replace(/[_-]+/g, '-')
    // Remove preceding and trailing hyphens
    .replace(/^[-]|[-]$/g, '');
