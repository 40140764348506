import { componentLabel } from "../utils/editor";
import objectsColumn from './objects-column.svg';

export default (editor, options) => {
  editor.BlockManager.add(options.componentType, {
    label: componentLabel('Waterfall', objectsColumn),
    category: options.category,
    content: { type: options.componentType },
  });
}
