import loadBlock from './block';
import loadComponent from './component';
import { customComponentsCategory } from '../utils/editor';

/**
 * Recommender component entry point.
 */
export default (editor, opts = {}) => {
  const defaultOptions = {
    componentType: 'Recommender',
    componentName: 'Recommender',
    category: customComponentsCategory,
  };

  const options = {
    ...defaultOptions,
    ...opts
  };

  loadComponent(editor, options);
  loadBlock(editor, options);
}
