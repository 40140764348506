import * as React from 'react';
import { ReactQuestionFactory, ReactElementFactory } from 'survey-react-ui';
import { RendererFactory, Serializer } from 'survey-core';
import {ProgramApplicationFormProgressBar} from './ProgramApplicationFormProgressBar';
import CustomSelect from './CustomSelect';

const registerCustomSelect = () => {
  // Register the CustomSelect component as a renderer under a custom name 'sv-dropdown-react'
  const customSelectCreator = (props) => React.createElement(CustomSelect, props);
  const rendererType = 'sv-dropdown-react';
  const renderAsValue = 'dropdown-react';

  ReactQuestionFactory.Instance.registerQuestion(rendererType, customSelectCreator);

  // Register 'sv-dropdown-react' as a renderer for questions whose `type` is 'dropdown' and `renderAs` property is 'dropdown-react'
  RendererFactory.Instance.registerRenderer('dropdown', renderAsValue, rendererType);

  // Set 'dropdown-react' as a default value for the `renderAs` property of all 'dropdown' questions
  Serializer.findProperty('dropdown', 'renderAs').defaultValue = renderAsValue;
}

const registerProgramApplicationFormProgressBar = () => {
  ReactElementFactory.Instance.registerElement('sv-pa-form-steps', props => React.createElement(ProgramApplicationFormProgressBar, props));
}

export default function () {
  registerCustomSelect();
  registerProgramApplicationFormProgressBar();
}
