const DEBUG_KEY = 'debug';

/**
 * Logs an error message
 * @param prefix {string}
 * @param error {Error}
 * @param context {Record<string, unknown>}
 * @return void
 */
export function addError(prefix, error, context = {}) {
  if (localStorage.getItem(DEBUG_KEY)) {
    console.error(`[${prefix}]`, error, context);
  }

  // Datadog
  window.DD_RUM && window.DD_RUM.addError(error, {...context, prefix});
}

/**
 * Logs a custom event
 *
 * @param prefix {string}
 * @param message {string}
 * @param context {Record<string, unknown>}
 */
export function addEvent(prefix, message, context) {
  window.DD_RUM && window.DD_RUM.addAction(message, context)
}

/**
 * Logs a message when debugging is enabled
 * @param name
 * @param context
 */
export function addMessage(name, context) {
  if (localStorage.getItem(DEBUG_KEY)) {
    console.info(`[${name}]`, context);
  }
}
