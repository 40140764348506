import { Serializer } from 'survey-core';
import {autocomplete} from '@algolia/autocomplete-js';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {createQuerySuggestionsPlugin} from "@algolia/autocomplete-plugin-query-suggestions";
import addressCardIcon from './icons/address-card.svg';
import {registerCustomIcon} from './icons';
import { getUserProfile } from '../utils/user';

registerCustomIcon('address-card', addressCardIcon);

const AUTOCOMPLETE_MIN_LENGTH = 3;

/**
 * Creates HTML input for autocomplete field.
 *
 * @param {String} id
 *   Base HTML element id.
 * @param {HTMLElement} input
 * @returns {HTMLDivElement}
 */
const createFakeAutocompleteInput = (id = '', input) => {
  const container = document.createElement('div');
  container.id = `${id}--autocomplete`;
  input.after(container);
  return container;
}

const prefillFieldValue = (q, element, currentUser) => {
  if (!currentUser) {
    return;
  }

  let fieldValue = null;

  switch (element.name) {
    case 'first-name':
      fieldValue = currentUser['first_name'];
      break;
    case 'last-name':
      fieldValue = currentUser['last_name'];
      break;
    case 'email':
      fieldValue = currentUser['email'];
      break;
    case 'job-role':
      fieldValue = currentUser['job_role'];
      break;
    case 'organization':
      fieldValue = currentUser['organization'];
      break;
    case 'organization-url':
      fieldValue = currentUser['organization_url'];
      break;
    case 'industry':
      fieldValue = currentUser['industry'];
      break;
    case 'location':
      fieldValue = currentUser['country'];
      if (fieldValue) {
        fieldValue = fieldValue.toUpperCase();
      }
      break;
    case 'interest-areas':
      fieldValue = currentUser['interest_areas'];
      element.defaultValue = fieldValue;
      break;
  }

  if (fieldValue && !element.value) {
    element.value = fieldValue;
  }
}

/**
 * Builds Typesense adapter.
 *
 * @param {String} host
 * @param {String} apiKey
 *
 * @returns {TypesenseInstantsearchAdapter}
 */
const buildTsClientAdapter = (host, apiKey) => {
  return new TypesenseInstantSearchAdapter({
    server: {
      apiKey: apiKey,
      nodes: [{ host: host, port: '443', path: '', protocol: 'https'}],
      cacheSearchResultsForSeconds: 0,
    },
    collectionSpecificSearchParameters: {
      companies: {
        query_by: 'name,aliases',
        per_page: 8,
        num_typos: 1
      }
    },
  });
};

const formHeader = () => {
  const parts = [];
  parts.push(`<p class='p--large mb-0'>${I18n.t('program_applications.form.header')}</p>`)
  parts.push(`<p class='p--medium mb-4'>${I18n.t('program_applications.form.subheader')}</p>`);

  return parts.join('');
}

const addTooltip = (htmlElement) => {
  const tooltipContent = document.createElement('div');
  tooltipContent.className = 'nv-q-tooltip__content'
  tooltipContent.innerHTML = I18n.t('program_applications.form.email_tooltip')

  const tooltipButton = document.createElement('button');
  tooltipButton.className = 'nv-q-tooltip__btn';
  tooltipButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336H192v48h24 80 24V336H296h-8V248 224H264 216 192v48h24 24v64H216zm72-144V128H224v64h64z"/></svg>';

  const classToToggle = 'visible';
  tooltipButton.addEventListener('click', () => {
    tooltipButton.classList.toggle(classToToggle)
    tooltipContent.classList.toggle(classToToggle);
  });

  const title = htmlElement.querySelector('.nv-q__title');
  if (title) {
    title.append(tooltipButton);

    title.parentElement.classList.add('nv-q-tooltip__container')
    title.parentElement.append(tooltipContent);

    document.addEventListener('click', function(event) {
      if (!tooltipButton.parentNode.contains(event.target)) {
        tooltipButton.classList.remove(classToToggle)
        tooltipContent.classList.remove(classToToggle);
      }
    });
  }
}

export const UserProfile = {
  name: "user-profile",
  title: "User profile",
  iconName: 'icon-address-card',
  elementsJSON: [
    {
      type: "html",
      name: "header",
      errorLocation: "bottom",
      html: "",
      visible: false,
    },
    {
      type: "text",
      name: "first-name",
      title: "First name",
      errorLocation: "bottom",
      isRequired: true,
    },
    {
      type: "text",
      name: "last-name",
      title: "Last name",
      errorLocation: "bottom",
      isRequired: true,
      startWithNewLine: false,
    },
    {
      type: "text",
      name: "email",
      title: "Email",
      errorLocation: "bottom",
      inputType: "email",
      isRequired: true,
    },
    {
      type: "dropdown",
      name: "job-role",
      title: "Job role",
      errorLocation: "bottom",
      isRequired: true,
      choicesByUrl: {
        url: "/api/taxonomy/job_roles",
        titleName: "label",
      },
      startWithNewLine: false,
    },
    {
      type: "text",
      name: "organization",
      errorLocation: "bottom",
      title: "Organization / University name",
      isRequired: true,
    },
    {
      type: "text",
      name: "organization-url",
      title: "Organization URL",
      errorLocation: "bottom",
      inputType: "url",
      startWithNewLine: false,
    },
    {
      type: "dropdown",
      name: "industry",
      title: "Industry",
      errorLocation: "bottom",
      hasOther: true,
      isRequired: true,
      choicesByUrl: {
        url: "/api/taxonomy/industries",
        titleName: "label"
      },
      visible: false,
    },
    {
      type: "dropdown",
      name: "location",
      title: "Location",
      errorLocation: "bottom",
      isRequired: true,
      choicesByUrl: {
        url: "/api/countries",
        titleName: "label",
      },
      visible: false,
      startWithNewLine: false,
    },
    {
      type: "checkbox",
      name: "interest-areas",
      title: "Development areas of interest",
      errorLocation: "bottom",
      isRequired: true,
      choicesByUrl: {
        url: "/api/taxonomy/interest_areas",
        titleName: "label"
      },
      visible: false,
    },
  ],
  tmpStorage: {},
  extendedProfileFields: ['industry', 'location', 'interest-areas'],
  onInit() {
    Serializer.addProperty('user-profile', {
      name: 'showExtendedProfile',
      type: 'boolean',
      default: false,
      category: 'general',
    });

    Serializer.addProperty('user-profile', {
      name: 'isProgramApplicationForm',
      type: 'boolean',
      default: false,
      category: 'general',
      canShow: false,
      visible: false,
    });

    Serializer.addProperty('user-profile', {
      name: 'disabledFields',
      default: [],
      category: 'general',
      canShow: false,
    });

    Serializer.addProperty('user-profile', {
      name: 'showEmailTooltip',
      type: 'boolean',
      default: false,
      category: 'general',
      canShow: false,
      visible: false,
    });

    const initTypesenseClient = () => {
      const tsHostElement = document.querySelector('meta[name="typesense-host"]');
      const tsKeyElement = document.querySelector('meta[name="typesense-key"]');
      if (!tsHostElement || !tsKeyElement) {
        return;
      }

      window.tsClientAdapter = buildTsClientAdapter(tsHostElement.content, tsKeyElement.content);
      this.tmpStorage['currentUser'] = getUserProfile();
    }

    initTypesenseClient();
  },
  onLoaded(question) {
    const {survey} = question;

    if (!survey) {
      return;
    }

    // Program application form customizations
    if (question.isProgramApplicationForm) {
      const {showEmailTooltip: showTooltip} = question;

      question.getQuestionByName('header').setPropertyValue('visible', true);

      // Alter header and footer
      survey.onAfterRenderQuestion.add((_, options) => {
        const {question: q, htmlElement} = options;
        switch (q.name) {
          case 'header':
            const contentContainer = htmlElement.querySelector('.sd-question__content');
            if (contentContainer) {
              contentContainer.innerHTML = formHeader();
            }
            break;
          case 'email':
            if (showTooltip) {
              addTooltip(htmlElement);
            }
            break;
        }
      });

      // Disable email field
      if (question.disabledFields) {
        this.disableFields(question.disabledFields, question);
      }

      // Add custom CSS class
      survey.onUpdateQuestionCssClasses.add(function (survey, options) {
        const {name} = options.question.getPlainData();
        if (name === 'user-profile' || options.question.name === 'user-profile') {
          options.cssClasses.mainRoot = `${options.cssClasses.mainRoot} sd-element--pa-form`;
        }
      });
    }

    survey.onUpdateQuestionCssClasses.add(function (survey, options) {
      const {name} = options.question.getPlainData();

      const orgFieldName = "organization";
      if (name === orgFieldName || orgFieldName === options.question.name) {
        options.cssClasses.mainRoot = `${options.cssClasses.mainRoot} organization-wrapper`;
        options.cssClasses.root = `${options.cssClasses.root} organization-input`;
      }

      const interestsFieldName = "interest-areas";
      if (name === interestsFieldName || options.question.name === interestsFieldName) {
        options.cssClasses.root = `${options.cssClasses.root} interest-areas-wrapper`;
      }
    });

    this.changeExtendedProfileVisibility(question);
  },
  onPropertyChanged(question, propertyName, newValue) {
    if (propertyName === 'showExtendedProfile') {
      this.changeExtendedProfileVisibility(question);
    }
  },
  onAfterRenderContentElement: function (question, element, htmlElement) {
    const {currentUser} = this.tmpStorage;

    prefillFieldValue(question, element, currentUser);

    if (element.name === 'organization') {
      const {tsClientAdapter} = window;

      if (!tsClientAdapter) {
        return;
      }

      const setElementValue = val => {
        val = val.trim();
        element.value = val;
      };

      const searchClient = tsClientAdapter.searchClient;
      const querySuggestionsPlugin = createQuerySuggestionsPlugin({
        searchClient,
        indexName: 'companies',
        getSearchParams({ state }) {
          return {
            hitsPerPage: 8,
            query: state.query ? state.query: '',
          };
        },
        transformSource({source}) {
          return {
            ...source,
            getItemUrl({ item }) {
              return null;
            },
            onSelect: function(event) {
              event.setQuery(event.item.name);
            },
            templates: {
              ...source.templates,
              item(params) {
                const { item, html } = params;
                return html`<button value="${item.name}" onclick="${() => {setElementValue(item.name);}}">${item.name}</button>`;
              },
            },
          };
        },
      });

      const input = htmlElement.querySelector('input');
      const fakeInput = createFakeAutocompleteInput(htmlElement.id, input);
      fakeInput.addEventListener('change', (event) => setElementValue(event.target.value));
      fakeInput.addEventListener('blur', (event) => setElementValue(event.target.value));
      const autocompleteOpts = {
        container: `#${fakeInput.id}`,
        placeholder: 'Choose...',
        detachedMediaQuery: 'none',
        debug: false,
        plugins: [querySuggestionsPlugin],
        classNames: {
          panel:  'org-autocomplete',
        },
        navigator: {
          navigate(data) {
            // Prevent redirect.
            const {item} = data;
            if (!item) {
              return false;
            }
            setElementValue(item.name);
            return false;
          }
        },
        shouldPanelOpen(params) {
          // https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-js/autocomplete/#param-shouldpanelopen
          const {state} = params;
          if (!state) {
            return false;
          }
          const {query} = state;
          setElementValue(query);

          return !(query && `${query}`.trim().length < AUTOCOMPLETE_MIN_LENGTH);
        },
        onSubmit({state, event}) {
          // Prevent redirect.
          return false;
        }
      };
      this.setAutocompleteInitialState(question, autocompleteOpts);
      autocomplete(autocompleteOpts);
    }
  },
  setAutocompleteInitialState(question, autocompleteOpts){
    const questionPlainData = question.getPlainData()
    const defaultValue = questionPlainData?.value?.organization;
    if (!defaultValue) {
      return;
    }
    autocompleteOpts.initialState = {query: defaultValue}
  },
  changeExtendedProfileVisibility(question) {
    const cp = question.contentPanel;

    for (const elementName of this.extendedProfileFields) {
      const q = cp.getQuestionByName(elementName);
      q.visible = question.showExtendedProfile;
    }
  },
  disableFields(fields, question) {
    const cp = question.contentPanel;

    for (const elementName of fields) {
      const q = cp.getQuestionByName(elementName);
      q.readOnly = true;
    }
  }
};
