import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

/**
 * Gallery Widget.
 *
 * @param {Object} props
 * @param {String} props.id
 * @param {String} props.playlist_id
 * @param {String} props.destination
 * @param {String} props.widget_type
 * @param {String} props.header
 * @param {Boolean} props.is_featured
 * @param {Boolean} props.is_featured_playlist
 * @param {String} props.hide_title
 * @param {Boolean} props.open_session_in_new_tab
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export default function GalleryWidget(props) {
  const [widgetSettings, setWidgetSettings] = useState({});

  const doDispatchSetupEvent = () => {
    const event = new CustomEvent('GalleryWidgetSetup', {detail: widgetSettings});
    const dispatcher = window.isEditorPage ? document.querySelector('.gjs-frame').contentWindow.document : document;

    if (window.isEditorPage) {
      document.querySelector('.gjs-frame').contentWindow.window.nodWidgetsRedrawMap.set(props.id, true);
    }

    dispatcher.dispatchEvent(event);
  };

  useEffect(() => {
    setWidgetSettings({
      id: props.id,
      playlistId: props.playlist_id,
      destination: props.destination,
      type: props.widget_type,
      header: props.header,
      isFeatured: props.is_featured,
      isFeaturedPlaylist: props.is_featured_playlist,
      hideTitle: props.hide_title,
      openSessionInNewTab: props.open_session_in_new_tab,
    });

    return () => {
      document.removeEventListener('nv.page-builder.loaded', doDispatchSetupEvent);
    };
  }, [props]);

  const dispatchSetupEvent = useCallback(node => {
    if (node !== null) {
      document.addEventListener('nv.page-builder.loaded', doDispatchSetupEvent);
      doDispatchSetupEvent();
    }
  }, [widgetSettings]);

  return (
    <div className='nv-gallery-widget' ref={dispatchSetupEvent}>
      <div id={props.id}></div>
    </div>
  );
}
