import Recommender from '../Recommender';

export default function (editor, options) {
  const {componentType, componentName} = options;

  const componentDefinition = {
    extend: 'react-component',
    model: {
      defaults: {
        'custom-name': componentName,
        component: Recommender,
        stylable: false,
        resizable: false,
        editable: false,
        draggable: true,
        droppable: true,
        traits: [
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            required: true,
          },
          {
            type: 'select',
            name: 'layout',
            label: 'Layout',
            options: [
              {id: '3-cols', name: '3 columns'},
              {id: '4-cols', name: '4 columns'},
            ],
          },
          {
            type: 'checkbox',
            name: 'overflow',
            label: 'Overflow',
            value: 1
          },
          {
            type: 'select',
            name: 'headingAlignment',
            label: 'Layout',
            options: [
              {id: 'left', name: 'Left'},
              {id: 'center', name: 'Center'},
            ],
            value: 'left'
          },
          {
            type: 'checkbox',
            name: 'showGreenElement',
            label: 'Show green element',
          }
        ],
      },
    },
    isComponent: el => el.tagName === componentType.toUpperCase()
  };
  editor.Components.addType(componentType, componentDefinition);
}
