import * as React from 'react';
import {Chevron, mappingHasProperty, toggleFilterState} from '../helper';
import {useContext, useEffect, useRef, useState} from 'react';
import {FieldsMappingContext} from '../Context';
import { Actions } from '../Actions';
import showdown from "showdown";

/**
 * Badge component.
 *
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const Badge = ({label}) => label ? <div className="fsf-result-accordion-item__badge">{label}</div> : <></>;

/**
 * Body component.
 *
 * @param {String} content
 * @returns {JSX.Element}
 * @constructor
 */
const Body = ({content}) => content ? <div className="fsf-result-accordion-item__body"><p>{content}</p></div> : <></>;

const Markdown = ({content}) => {
  if (!content) {
    return null;
  }
  let converter = new showdown.Converter({moreStyling: true});
  const html = converter.makeHtml(content);
  return (
    <div
      className="fsf-result-accordion-item__body fsf-result-accordion-item__body-md"
      dangerouslySetInnerHTML={{__html: html}}
    ></div>
  );
}

/**
 * Description list.
 *
 * @param {Array} list
 * @param {Array} list
 * @returns {JSX.Element}
 * @constructor
 */
const DescriptionList = ({list}) => {
  if (!Array.isArray(list) || !list.length) {
    return <></>
  }
  return <div className={'fsf-result-accordion-item__description-list'}>
    {list.map((row, key) => {
      return (row.label && row.items) ? <DescriptionListRow key={key} label={row.label} items={row.items}/> : <></>;
    })}
  </div>;
}

/**
 * Description list row.
 *
 * @param {Object} props
 * @param {String} props.label
 * @param {Array} props.items
 * @returns {JSX.Element}
 * @constructor
 */
const DescriptionListRow = (props) => {
  const {label, items} = props;
  if (!items || (Array.isArray(items) && items.length === 0)) {
    return <></>;
  }

  return <div className="fsf-result-accordion-item__description-list-row">
    <div className="fsf-result-accordion-item__description-list-row-label">{label}:</div>
    <div className="fsf-result-accordion-item__description-list-row-content">
      <p className="mb-0">{Array.isArray(items) ? items.join(', ') : items}</p>
    </div>
  </div>
};

/**
 * Accordion view.
 *
 * @param {Object} hit
 * @returns {JSX.Element}
 * @constructor
 */
export const AccordionSearchResult = ({ hit }) => {
  const panelElement = useRef();
  const {accordion} = useContext(FieldsMappingContext);
  const [mapping] = accordion;

  const [title, setTitle] = useState('');
  const [badge, setBadge] = useState('');
  const [body, setBody] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [descriptionList, setDescriptionList] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (mappingHasProperty(mapping, hit, 'title')) {
      setTitle(hit[mapping['title']]);
    }
    if (mappingHasProperty(mapping, hit, 'badge')) {
      setBadge(hit[mapping['badge']]);
    }
    if (mappingHasProperty(mapping, hit, 'body')) {
      setBody(hit[mapping['body']]);
    }

    if (mappingHasProperty(mapping, hit, 'markdown')) {
      setMarkdown(hit[mapping['markdown']]);
    }

    // Description list.
    if (mapping.hasOwnProperty('descriptionList')) {
      const descriptionList = [];
      const parsedList = mapping['descriptionList'].split("\n").map(s => s.trim());

      parsedList.forEach((value, index) => {
        let items = hit[value];
        if (items === undefined) {
          return;
        }
        if (!Array.isArray(items)) {
          items = [items];
        }
        const row = {label: value, items: items};

        if (mapping['descriptionListLabels']) {
          const listLabels = mapping['descriptionListLabels'].split("\n").map(s => s.trim());
          row.label = listLabels[index] ? listLabels[index] : row.label;
        }

        descriptionList.push(row);
      });

      setDescriptionList(descriptionList);
    }

    // Links.
    if (mappingHasProperty(mapping, hit, 'links')) {
      setLinks(hit[mapping['links']]);
    }
  }, []);

  return <div className="fsf-result-accordion-item" ref={panelElement}>
    <div className="fsf-result-accordion-item__heading" onClick={() => toggleFilterState(panelElement.current, 'expanded')}>
      <div className="fsf-result-accordion-item__heading-main">
        <h3 className={'h--smallest mb-0'}>{title}</h3>
        <Badge label={badge} />
      </div>
      <div className="fsf-result-accordion-item__heading-toggler">
        <Chevron />
      </div>
    </div>
    <div className="fsf-result-accordion-item__content">
      <Body content={body} />
      <Markdown content={markdown} />
      <DescriptionList list={descriptionList} />
      <Actions {...{links}} />
    </div>
  </div>;
};
