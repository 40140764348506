import componentIcon from './icon.svg';
import {componentType} from './editor';
import {componentLabel} from "../utils/editor";

export function loadBlock(editor, options) {
  editor.BlockManager.add(componentType, {
    label: componentLabel('Faceted search', componentIcon),
    category: 'React Components',
    activate: true,
    select: true,
    content: '<FacetedSearch />'
  });
}
