import {componentType} from './editor';
import FacetedSearch from '../FacetedSearch';
import {handleRequestError} from './react/helper';
import {TraitTypeOptionsSet} from '../traits/options-set';
import { layouts, mappingTypes } from './data-mapping';

export function loadComponent(editor, options) {
  const componentDefinition = {
    extend: 'react-component',
    model: {
      init() {
        const layoutTrait = this.getTrait('layout');
        if (layoutTrait) {
          const layout = layoutTrait.getValue();
          if (layout !== layouts.standard || layout !== layouts.compact) {
            layoutTrait.setValue(layouts.standard);
          }
        }

        fetch('/nv/admin/typesense_collections.json')
          .then(data => data.json())
          .then(data => {
            const collections = data.map((collection) => {
              return {
                id: collection.id,
                label: collection.label,
              };
            });
            const selectBox = this.getTrait('collection_id');
            if (selectBox) {
              selectBox.set('options', collections);
            }
          })
          .catch(e => handleRequestError(e));
        this.on('options-set-update', this.handleOptionsSetUpdate);
      },
      handleOptionsSetUpdate(attributes) {
        const {view_mode} = attributes;
        if (view_mode) {
          const cardOptionsTrait = this.getTrait('card_options');
          if (view_mode === mappingTypes.card) {
            cardOptionsTrait.el.classList.remove('gjs-visually-hidden');
          } else {
            cardOptionsTrait.el.classList.add('gjs-visually-hidden');
          }
        }
      },
      updated(property, value, prevValue) {
        const cardOptionsTrait = this.getTrait('card_options');
        if (property === 'attributes' && cardOptionsTrait && cardOptionsTrait.el) {
          if (value.view_mode === mappingTypes.card) {
            cardOptionsTrait.el.classList.remove('gjs-visually-hidden');
          } else {
            cardOptionsTrait.el.classList.add('gjs-visually-hidden');
          }
        }
      },
      defaults: {
        component: FacetedSearch,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: true,
        traits: [
          {
            type: 'select',
            label: 'Layout',
            name: 'layout',
            options: options.layouts,
          },
          {
            type: 'text',
            name: 'title',
            label: 'Title'
          },
          {
            type: 'select',
            label: 'Typesense collection',
            name: 'collection_id'
          },
          {
            type: 'text',
            name: 'query_by',
            label: 'Query by',
            placeholder: 'title,description',
            required: true,
          },
          {
            type: 'text',
            name: 'sort_by',
            label: 'Sort by',
            placeholder: 'title:asc,description:desc',
          },
          {
            type: 'text',
            name: 'sort_by_label',
            label: 'Sort by label',
            placeholder: '',
          },
          {
            type: 'text',
            name: 'filter_by',
            label: 'Filter by',
            placeholder: 'content_type:=Documentation',
          },
          {
            name: 'description_filter_by',
            type: 'description',
            text: '<p class="p--small fst-italic">For details, see <a href="https://typesense.org/docs/26.0/api/search.html#filter-parameters" target="_blank">Typesense documentation</a>.</p>' +
              '<p>Example: <code>content_type:=Documentation</code>, <code>learning_levels:=[`Advanced`, `Beginner`]</code></p>',
          },
          {
            type: 'text',
            name: 'disabled_filters',
            label: 'Disabled filters',
            placeholder: 'products,learning_levels',
          },
          {
            type: 'select',
            label: 'View mode',
            name: 'view_mode',
            options: options.viewModes
          },
          {
            type: TraitTypeOptionsSet,
            label: 'Card options',
            name: 'card_options',
            options: options.cardOptions
          },
          {
            type: 'number',
            label: 'Items per page',
            name: 'items_per_page',
            placeholder: 'Defaults to 48 (min: 12, max: 120)',
            min: 6,
            max: 120,
            step: 1
          },
          {
            type: TraitTypeOptionsSet,
            label: 'Compact mode',
          },
          {
            type: 'text',
            name: 'compact_mode_dropdown_label',
            label: '"Filter by" dropdown label',
            placeholder: '',
          },
          {
            type: 'text',
            name: 'compact_mode_cta_text',
            label: 'CTA text',
            placeholder: '',
          },
          {
            type: 'text',
            name: 'compact_mode_cta_url',
            label: 'CTA URL',
            placeholder: '',
          },
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === 'FACETEDSEARCH';
    }
  };
  editor.Components.addType(componentType, componentDefinition);
}
