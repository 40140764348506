import { componentLabel } from '../utils/editor';
import cardsBlankIcon from './cards-blank.svg';

export default function (editor, options) {
  const {componentType, category, componentName} = options;

  editor.BlockManager.add(componentType, {
    label: componentLabel(componentName, cardsBlankIcon),
    category: category,
    content: '<Recommender />'
  });
}
