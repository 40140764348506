import * as React from 'react';
import { connectStats } from 'react-instantsearch-dom';

const StatsWithResults = ({hitsCount}) => {
  return <p className="h--smaller mb-0">
    {I18n.t('faceted_search.stats.results_found', {count: hitsCount})}
  </p>
}

const StatsWithoutResults = () => {
  return <p className="h--smaller mb-0">{I18n.t('faceted_search.stats.no_results')}</p>
};

const Stats = ({ nbHits }) => {
  return <div className="faceted-search-stats">
    {nbHits && nbHits > 0 ? <StatsWithResults hitsCount={nbHits}/> : <StatsWithoutResults/>}
  </div>
};

export const FacetedSearchStats = () => {
  const CustomStats = connectStats(Stats);
  return <CustomStats/>;
};
