import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';

/**
 * Image comparison slider component.
 *
 * @param {Object} props
 * @param {String} props.image_1_url
 * @param {String} props.image_1_alt
 * @param {String} props.image_2_url
 * @param {String} props.image_2_alt
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
function ImageComparisonSlider(props) {
  return (
    <ImgComparisonSlider className="nv-img-comparison-slider">
      <img slot='first' src={props.image_1_url} alt={props.image_1_alt} />
      <img slot='second' src={props.image_2_url} alt={props.image_2_alt} />
    </ImgComparisonSlider>
  );
}

ImageComparisonSlider.defaultProps = {
  image_1_url: "https://d29g4g2dyqv443.cloudfront.net/sites/default/files/images/img-comparison-before.jpg",
  image_2_url: 'https://d29g4g2dyqv443.cloudfront.net/sites/default/files/images/img-comparison-after.jpg'
}

export default ImageComparisonSlider;
