import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import AccordionItem from './AccordionItem';
import parse, {attributesToProps} from 'html-react-parser';

/**
 *
 * @param {HTMLElement} item
 * @returns {React.JSX.Element|null}
 */
function buildAccordionItem(item) {
  if (!item.outerHTML) {
    return null;
  }

  return parse(item.outerHTML, {
    replace(domNode) {
      const props = attributesToProps(domNode.attribs);
      if (props['data-react-class'] && props['data-react-class'] === 'AccordionItem') {
        const reactProps = JSON.parse(props['data-react-props']);
        let {id, title, badge, expanded, size} = reactProps;
        if (title) {
          title = title.toString().trim();
        }
        if (badge) {
          badge = badge.toString().trim();
        }
        expanded = expanded || false;

        return <AccordionItem key={id} id={id} title={title} badge={badge} expanded={expanded} size={size}>
          {parse(item.querySelector('.accordion-item__content').innerHTML)}
        </AccordionItem>
      }
    },
  });
}

const Accordion = (props) => {
  let { children, childSelector } = props;
  const [items, setItems] = useState([])

  let childElement = null;
  if (childSelector) {
    childElement = document.querySelector(childSelector)
  }

  const childElementRef = useRef(childElement);
  if (childElementRef && childElementRef.current) {
    children = childElementRef.current.innerHTML;
    if (childElementRef.current.parentElement) {
      childElementRef.current.parentElement.removeChild(childElementRef.current);
    }
  }

  let childContainer = document.createElement('div');
  childContainer.innerHTML = children;
  useEffect(() => setItems(Array.from(childContainer.childNodes)), [children]);

  const cssClasses = {
    root: classnames('nv-accordion'),
  };

  return (
    <div className={cssClasses.root}>
      {childSelector && <div className='accordion-items'>{items.map((item) => buildAccordionItem(item))}</div>}
      {!childSelector && <div className='accordion-items-preview'>{children}</div>}
    </div>
  );
};

export default Accordion;
