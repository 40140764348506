import * as React from 'react';
import { useContext, useEffect, useState, useRef } from 'react';
import { FieldsMappingContext, QueryContext, ViewOptionsContext } from '../Context';
import { Actions } from '../Actions';
import { mappingHasProperty } from '../helper';
import { slugify } from '../../../utils/slugify';
import classnames from 'classnames';

/**
 * Image component.
 *
 * @param {String} url
 * @param {String} title
 * @returns {JSX.Element}
 * @constructor
 */
const HeroImage = ({url, title}) => {
  if (!url) {
    return <></>
  }
  return <div className="faceted-search-card__image">
    <img className="img-fluid" src={url} alt={title} title={title}/>
  </div>
}

const ListValues = ({items, truncate = false}) => {
  const elementRef = useRef(null);
  const cssClasses = classnames({
    'list-values': true,
    'text-truncate': truncate,
  });

  useEffect(() => {
    if (truncate && elementRef.current) {
      const initialRect = elementRef.current.getBoundingClientRect();
      elementRef.current.classList.remove('text-truncate');
      const originalRect = elementRef.current.getBoundingClientRect();
      if (originalRect.height > initialRect.height) {
        elementRef.current.classList.add('text--clickable');
        elementRef.current.classList.add('text-truncate');
      }
    }
  }, []);

  let onClickHandler = () => {
    // Nothing to do by default.
  };

  if (truncate) {
    onClickHandler = () => {
      if (!elementRef.current) {
        return;
      }
      elementRef.current.classList.toggle('text-truncate');
    };
  }

  return (
    <div className={cssClasses} ref={elementRef} onClick={onClickHandler}>
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <span className="item">{item}</span>
          {idx !== items.length - 1 && <span className="spacer">|</span>}
        </React.Fragment>
      ))}
    </div>
  );
}

const PretitleField = ({value, truncate}) => {
  if (!value) {
    return null;
  }

  const cssClasses = classnames({
    'p--medium': true,
    'faceted-search-card__pretitle': true,
    'faceted-search-card__pretitle--truncated': !!truncate,
  });

  return <div className={cssClasses}>
    {(Array.isArray(value)) ? <ListValues items={value} truncate={truncate}/> : value}
  </div>;
}

const Title = ({value}) => !value ? <></> : <h3 className="h--smallest faceted-search-card__title">{value}</h3>;

const SubtitleField = ({value}) => {
  if (!value) {
    return null;
  }

  return <div className="p--medium faceted-search-card__subtitle">{(Array.isArray(value)) ? <ListValues items={value}/> : value}</div>;
}

const BodyField = ({value}) => !value ? <></> : <p className="mt-0 mb-0 faceted-search-card__body">{value}</p>;

/**
 * Stretched CTA link component.
 *
 * @param {Object} link
 * @returns {JSX.Element}
 * @constructor
 */
const StretchedCtaLink = ({link}) => {
  if (typeof link === 'undefined' || (typeof link !== 'object' && typeof link.url !== 'string')) {
    return <></>;
  }

  let linkAttr = {
    href: link.url,
    className: 'stretched-link'
  };

  if (link.target) {
    linkAttr.target = link.target;
  }

  return <a {...linkAttr}></a>;
}

function getCategory(value) {  // TODO: finalize content type to category mappings
  const categoryMap = {
    instructional: ['tutorial', 'documentation', 'training', 'guide'],
    informational: ['workflows', 'tech-blog'],
    code: ['code-sample', 'oss', 'notebook', 'sample-app'],
    featured: ['featured']
  };

  for (let category in categoryMap) {
    if (categoryMap[category].includes(value)) {
      return category;
    }
  }

  return 'misc';
}

const badgeClasses = (value) => `badge badge--${getCategory(value)}`;

const Badges = ({items}) => {
  if (!items) {
    return null;
  }

  return (
    <div className='card-badges'>
      {items.length > 0 && items.map(t => {
        return <div key={t.key} className={badgeClasses(t.key)}>{t.value}</div>
      })}
    </div>
  );
};

export const CardSearchResult = (props) => {
  const {hit} = props;
  const {card} = useContext(FieldsMappingContext);
  const [mapping] = card;

  const {card: cardViewOptionsCtx} = useContext(ViewOptionsContext);
  const [cardViewOptions] = cardViewOptionsCtx;

  const {filterBy: filterByCtx} = useContext(QueryContext);
  const [filterByValue] = filterByCtx;

  let {isClickableCard, hasImagePadding, truncatePretitle} = cardViewOptions;

  const [imageUrl, setImageUrl] = useState('');
  const [badges, setBadges] = useState([]);
  const [pretitle, setPretitle] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [body, setBody] = useState('');
  const [links, setLinks] = useState([]);

  // Map fields.
  useEffect(() => {
    if (mappingHasProperty(mapping, hit, 'imageUrl')) {
      setImageUrl(hit[mapping['imageUrl']]);
    }

    // Badges
    if (mappingHasProperty(mapping, hit, 'badges', true)) {
      let badgeFields = mapping['badges']
        .split(',')
        .map(v => v.trim());
      const tmpBadges = [];

      if (filterByValue && filterByValue.includes("topics:=")) {
        const topicsRegex = /topics:=\[(.*?)\]/;
        const topicsMatch = filterByValue.match(topicsRegex);

        let featuredInFields;
        if (topicsMatch && topicsMatch[1]) {
          featuredInFields = topicsMatch[1].split(',').map(item => item.trim().replace(/[`'"]/g, ''));
        }

        // Delete featured field
        badgeFields = badgeFields.filter(item => item !== "featured");
        const {featured_in} = hit;
        if (Array.isArray(featuredInFields) && featuredInFields.length) {
          for (const item of featuredInFields) {
            if (featured_in && featured_in[item]) {
              tmpBadges.push({key: 'featured', value: 'Featured'});
            }
          }
        }
      }

      badgeFields
        .filter(v => hit[v])
        .forEach(v => {
          if (typeof hit[v] === 'boolean' && v === 'featured') {
            tmpBadges.push({key: 'featured', value: 'Featured'});
          } else if (typeof hit[v] === 'string') {
            tmpBadges.push({key: `${slugify(hit[v])}`, value: hit[v]});
          }
        });
      if (tmpBadges.length) {
        setBadges(tmpBadges);
      }
    }

    if (mappingHasProperty(mapping, hit, 'pretitle')) {
      setPretitle(hit[mapping['pretitle']]);
    }

    if (mappingHasProperty(mapping, hit, 'title')) {
      setTitle(hit[mapping['title']]);
    }

    if (mappingHasProperty(mapping, hit, 'subtitle')) {
      setSubtitle(hit[mapping['subtitle']]);
    }

    if (mappingHasProperty(mapping, hit, 'body')) {
      setBody(hit[mapping['body']])
    }

     // Links.
    if (mappingHasProperty(mapping, hit, 'links')) {
      setLinks(hit[mapping['links']]);
    }
  }, [isClickableCard, hasImagePadding, hit]);

  const cssClasses = classnames({
    'faceted-search-card': true,
    'faceted-search-card--with-image-padding': !!hasImagePadding,
    'faceted-search-card--clickable-card': !!isClickableCard,
  });

  return <div className={cssClasses}>
    <HeroImage url={imageUrl} title={title}/>
    <div className="faceted-search-card__content">
      {badges.length > 0 && <Badges items={badges}/>}
      <PretitleField value={pretitle} truncate={!!truncatePretitle}/>
      <Title value={title}/>
      <SubtitleField value={subtitle}/>
      <BodyField value={body}/>
      {!isClickableCard && <Actions {...{links}} />}
      {isClickableCard && <StretchedCtaLink link={links[0]} />}
    </div>
  </div>;
};
