import Accordion from '../Accordion';
import AccordionItem, {AccordionSize} from '../AccordionItem';
import {componentWithChildrenToHTML} from '../utils/editor';

export const loadAccordionComponent = (editor, options) => {
  const componentDefinition = {
    extend: 'react-component',
    model: {
      toHTML(opts = {}) {
        return componentWithChildrenToHTML(this);
      },
      defaults: {
        components: [
          {
            type: 'AccordionItem',
            attributes: {
              title: 'Accordion item title'
            }
          }
        ],
        component: Accordion,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: true,
        droppable: (target, destination) => {
          const allowedTypes = ['AccordionItem'];
          return allowedTypes.includes(target.get('type'));
        },
        traits: [
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === options.componentType.toUpperCase();
    }
  };
  editor.Components.addType(options.componentType, componentDefinition);
};

export const loadAccordionItemComponent = (editor, options) => {
  const componentDefinition = {
    extend: 'react-component',
    model: {
      toHTML(opts = {}) {
        return componentWithChildrenToHTML(this);
      },
      defaults: {
        components: [],
        component: AccordionItem,
        stylable: false,
        resizable: false,
        editable: true,
        draggable: (target, destination) => {
          const allowedTypes = ['Accordion'];
          return allowedTypes.includes(destination.get('type'));
        },
        droppable: (target, destination) => {
          const disallowed = ['Accordion', 'AccordionItem'];
          return !disallowed.includes(target.get('type'));
        },
        attributes: {},
        name: 'Accordion item',
        traits: [
          {
            type: 'text',
            name: 'title',
            label: 'Title'
          },
          {
            type: 'select',
            name: 'size',
            label: 'Size',
            options: (() => {
              const items = [];
              for (const [key, item] of Object.entries(AccordionSize)) {
                items.push({ id: item.id, name: item.name})
              }
              return items;
            })(),
          },
          {
            type: 'text',
            name: 'badge',
            label: 'Badge'
          },
          {
            type: 'checkbox',
            name: 'expanded',
            label: 'Expanded'
          }
        ],
      },
    },
    isComponent: (el) => {
      return el.tagName === options.componentType.toUpperCase();
    }
  };
  editor.Components.addType(options.componentType, componentDefinition);
};
